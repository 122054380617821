.partners-container {
  width: 75%;
  margin: 0 auto;
  padding: 20px 0;
}

.our-partners-grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 40px;
  margin-top: 1rem;
}

.partner-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

@media (max-width: 768px) {
  .partner-image {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .our-partners-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
  }
}

.partner-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

@media (max-width: 768px) {
  .partner-image {
    width: 50%;
  }
}

.partner_heading_small {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem 0;
  color: #000;
}

.media-data {
  position: relative;
  width: 100%;
  height: 500px;
}


.media-banner {
  position: relative;
  width: 100%;
  height: 100%;
}

.media-button {
  position: absolute;
  top: 70%;
  left: 9%;
  padding: 10px 15px;
  background-color: #800888;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.insurance-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  color: #f6931e;
}