/* FAQ.css */

.brand-faq-container {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 16px;
}

.brand-faq-question {
  font-weight: bold;
  font-size: 1.1rem;
}

.brand-faq-answer {
  margin-top: 8px;
  font-size: .9rem;
}