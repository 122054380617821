.center_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pos_input_container {
    background-color: white;
    width: 707px;
}

.imageViewer {
    cursor: pointer
}

div .logo {
    cursor: pointer
}

.pos_input_container h3 {
    font-weight: 750;
    font-size: 18px;
    color: #605F5E;
    margin: 20px 0px 0px 20px;
    font-family: sans-serif;
}

.pos_input_container form {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    /* align-items: center; */

}

.input_box_divider {
    width: 46%;
}

.input_box {
    height: 50px;
    width: 100%;
    border-radius: 7px;
    margin-top: 1.3rem;
    border: 1px solid #B7B7B7;
    font-size: 10px;
    outline: none;
    position: relative;
}

.segment_box {
    width: 94%;
}

.input_box p {
    padding-top: .3rem;
    padding-left: 1.2rem;
    color: #6F6F6F;
    font-size: 12px;
    font-weight: 560;
    margin-bottom: 10px;
}

.reviewCertInputNum {
    padding-top: .3rem;
    padding-left: 1.2rem;
    color: #6F6F6F;
    font-size: 12px;
    font-weight: 560;
    margin-bottom: 10px;
    text-align: left !important;
}

.mob_num_container {
    position: absolute;
    align-items: center;
    left: 1.2rem;
    width: 100%;
    bottom: 6px;
}

.num_mob_one {
    font-size: 14px;
    padding-left: 9px;
    margin-top: -10px;
}

.mob_num_input_new {
    padding-left: 1.5rem;
}

.input_box input {
    bottom: 4px;
    border: none;
    left: 1.2rem;
    outline: none;
    position: absolute;
    background-color: transparent;
    font-size: 14px;
    color: #1B3557;
    font-weight: 550;
    width: 80%;
}

input.mob_num_input {
    bottom: 0px;
    left: 1.8rem;
    border: none;
    outline: none;
    /* position: relative !important; */
    background-color: transparent !important;
    font-size: 14px;
    color: #1B3557;
    font-weight: 550;
    width: 80%;
}

.num_mob {
    bottom: 2px;
    font-size: 14px;
    border-right: #1B3557;
    font-weight: 550;
}

.input_box input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: #1B3557;
}

.input_box select {
    bottom: 9px;
    border: none;
    outline: none;
    width: 90%;
    position: absolute;
    background-color: transparent;
    font-size: 15px;
    font-weight: 550;
    left: 1rem;
    color: #1B3557;
}



.input_box select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: #1B3557;
}

.btn_register {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_register button {
    width: 174px;
    height: 50px;
    margin-top: 40px;
    background-color: #F6931E;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 9px;
    cursor: pointer;
}


.error-container-pos {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0.4rem;
    z-index: 1;
    align-items: flex-end;
    top: -1.7rem;
}

.error-msg-container-pos {
    padding: .4rem 0.8rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    font-size: 12.8px;
    color: #eee;
    font-weight: 600;
}

.errArrow-pos {
    width: 0;
    height: 0;
    border-top: 10px solid #F6931E;
    border-left: 10px solid transparent;
    margin-right: .5rem;
}

.error-icon-pos {
    width: 20px;
    margin-top: .2rem;
}