.partner-broker-section {
  margin-bottom: 20px;
}

.checkbox-container {
  margin-bottom: 10px;
}

.partner-broker-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.partnerBrokerLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.partnerBrokerInput,
.partnerBrokerSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.partner-broker-table-container {
  margin-top: 20px;
}

.partnerBrokerTable {
  width: 100%;
  border-collapse: collapse;
}

.partnerBrokerTableHeader,
.partnerBrokerTableData {
  border: 1px solid #ddd;
  padding: 8px;
}

.partnerBrokerTableHeader {
  background-color: #f4f4f4;
  text-align: left;
}