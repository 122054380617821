.sitemap-container {
  font-family: inter;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: auto;
}

.section {
  margin-bottom: 30px;
}

.sitemap_heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #10100f;
  background-color: #f2f2f2;
  margin-left: -5px;
  padding: 5px;
}

h3 {
  font-size: 15px;
  margin-bottom: 15px 0;
  font-weight: bold;
  text-decoration: underline;
}

.image {
  width: 100%;
  object-fit: cover;
  height: 400px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
  cursor: pointer;
}

li:hover {
  color: #f6931E;
}

.blog_container {
  display: flex;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
}

.blog_section {
  width: 75%;
  margin: auto;
  overflow-x: auto;
}

.learnMore {
  padding-left: 20px;
  color: #f6931E;
  font-weight: 600;
  text-decoration: underline;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .sitemap-container {
    width: 90%;
    flex-direction: column;
  }

  .section {
    width: 100%;
    margin-bottom: 20px;
  }

  .blog_section {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .image {
    height: 250px;
  }

  .section {
    width: 100%;
    margin-bottom: 20px;
  }

  .blog_container {
    flex-wrap: wrap;
  }

  .learnMore {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .sitemap_heading {
    font-size: 16px;
    padding: 8px;
    margin-left: 0;
  }

  .image {
    height: 200px;
  }

  h3 {
    font-size: 14px;
  }

  li {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .learnMore {
    font-size: 14px;
  }

  .section {
    width: 100%;
    margin-bottom: 20px;
  }

  .blog_section {
    width: 100%;
    padding: 10px;
  }

  .blog_container {
    flex-direction: column;
    align-items: center;
  }
}