/* TableSection.css */

.section-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.left-section,
.right-section {
  flex: 1;
  margin-right: 10px;
  /* Add gap between tables */
}

.left-section table,
.right-section table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.left-section th,
.right-section th,
.left-section td,
.right-section td {
  padding: 10px;
  text-align: left;
}

.left-section th,
.right-section th {
  background-color: #f2f2f2;
}

.left-section th:first-child,
.right-section th:first-child {
  text-align: left;
}

.left-section td,
.right-section td {
  border-top: 1px solid #ddd;
}

.left-section th,
.left-section td {
  border-right: 1px solid #ddd;
}

.left-section th:last-child,
.left-section td:last-child {
  border-right: none;
}

.right-section th,
.right-section td {
  border-left: 1px solid #ddd;
}

.right-section th:last-child,
.right-section td:last-child {
  border-left: none;
}