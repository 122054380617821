.payment-mode-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.payment-mode-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.payment-mode-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.paymentModeLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.paymentModeInput,
.paymentModeSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[disabled] {
  background-color: #f0f0f0;
}

.required input,
.required select {
  border-color: red;
}