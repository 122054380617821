.campaign-policy-status-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.campaign-policy-status-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.campaign-policy-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

/* Label Styling */
.campaignPolicyLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input and Select Styling */
.campaignPolicyInput,
.campaignPolicySelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Required fields with red border */
.required .campaignPolicyInput,
.required .campaignPolicySelect {
  border-color: red;
}

/* Button Group Styling */
.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

/* Save Button Styling */
button.save-btn {
  background-color: #20c997;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

/* Exit Button Styling */
button.exit-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

button.save-btn:hover {
  background-color: #17a589;
}

button.exit-btn:hover {
  background-color: #ff1a1a;
}