.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 2rem;
}

.partner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}