/* BlogList.css */
.blog_container_section {
  width: 80%;
  margin: 0 auto;
}

.blog_para_mid_section {
  padding: 10px;
}

.blog_page_sec_1 {
  width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  position: relative;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
}

.blog_img_cat {
  position: relative;
}

.blog_img_sec_1 {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  object-fit: fit;
  aspect-ratio: 16/9;
}

.blog_para-cat_sec_1 {
  position: absolute;
  bottom: 20px;
  left: 1.4rem;
  padding: 1px 7px;
  background-color: #F6931E;
  color: #fff;
  border-radius: 10px;
  font-size: 0.7rem;
}

.blog_h2_sec_1 {
  color: #333;
  margin-top: 15px;
  margin-bottom: 0px !important;
}

.blog_h3_sec_1 {
  color: #666;
  margin-top: 5px;
}

.blog_a_sec_1 {
  display: inline-block;
  margin-top: 15px;
  padding: 7px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  margin-left: 30px;

  &:hover {
    background-color: #0056b3;
  }
}

.blogList-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 50px;
  padding:10px
}

/* .blog_link_sec_1 {
  position: absolute;
  bottom: 20px;
} */

.blog_para_sec_1 {
  color: #000000;
  font-size: 14px;

}

/* BlogDetail.css */
.blog_detail_container {
  width: 75%;
  margin: 0 auto;
  padding: 50px 20px 20px 20px;
  border-radius: 8px;
}

.blog_detail_image {
  width: 100%;
  height: 600px !important;
  object-fit: fill;
  aspect-ratio: 16/9;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog_detail_category {
  margin: 10px 0 10px 0;
  font-style: italic;
  text-align: center;
  color: #F6931E;
  font-size: 18px;
  font-weight: 600;
}

.blog_detail_heading {
  color: #000;
  margin: 10px 0 0 0;
  font-size: 57px;
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
}

.blog_detail_subheading {
  color: #000;
  font-weight: bold;
  margin: 25px 0 25px 0;
  font-size: 25px;
  line-height: 1.6;
  text-decoration: none !important;
}

.blog_detail_description {
  color: #000;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.blog_detail_download_link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}

.details_span {
  color: #F6931E;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 4px 8px;
  background-color: #fff;
  margin-right: 8px;
}

.blog_timespan {
  color: #666;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.list-disc-cms {
  list-style-type: disc !important;
  padding-left: 20px !important;
}

.list-decimal-cms {
  list-style-type: decimal !important;
  padding-left: 20px !important;
}

.list-disc-li {
  margin-left: 20px !important;
  font-size: 18px;
  color: #000;
}

.list-disc-li:hover {
  color: #000000;
  cursor: auto
}

.author_section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.blog_detail_author_name {
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.blog_detail_author_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.inner-container {
  width: 75%;
  margin: 0 auto;
}


/* media query for responsive */
@media (max-width: 768px) {
  .blog_container_section {
    width: 100%;
  }

  .blog_detail_container {
    width: 100%;
    margin: 0 auto;
    padding: 50px 5px 20px 5px;
  }

  .blog_detail_image {
    height: 300px !important;
  }

  .blog_detail_heading {
    font-size: 30px;
    padding-top: 20px;
  }

  .blog_detail_subheading {
    font-size: 20px;
  }

  .blog_detail_description {
    font-size: 16px;
  }

  .blog_detail_author_name {
    font-size: 13px;
  }

  .blog_detail_author_image {
    width: 40px;
    height: 40px;
  }

  .blog_img_sec_1 {
    height: 300px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .blog_detail_container {
    padding: 50px 5px 20px 5px;
  }

  .blog_detail_image {
    height: 400px !important;
  }

  .blog_detail_heading {
    font-size: 40px;
  }

  .blog_detail_subheading {
    font-size: 20px;
  }

  .blog_detail_description {
    font-size: 16px;
  }

  .blog_detail_author_name {
    font-size: 13px;
  }

  .blog_detail_author_image {
    width: 40px;
    height: 40px;
  }

  .blog_img_sec_1 {
    height: 300px !important;
  }
}