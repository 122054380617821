:root {
  --primary-color: #F6931E;
  --secondary-color: #50E3C2;
  --background-color: #F4F6F9;
  --text-color: #4A4A4A;
  --input-border-color: #D9D9D9;
  --button-hover-color: #d6923e;
  --button-gradient: linear-gradient(to right, #F6931E, #d6923e);
}

.reset-app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  padding: 20px;
  background-color: var(--background-color);
}

.reset-page-container {
  background-color: #fff;
  color: var(--text-color);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 20px;
  text-align: center;
}

.page-title {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-color);
}

.page-subtitle {
  margin-bottom: 30px;
  font-size: 18px;
  color: #7F8C8D;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  width: 400px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input_margin {
  margin-bottom: 20px;
}

.input-field:focus {
  outline: none;
  border-color: var(--primary-color);
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn_margin {
  margin-top: 20px;
}

.btn-primary,
.btn-secondary {
  width: 45%;
  padding: 15px 0;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: none;
}

.btn-primary {
  background: var(--button-gradient);
  color: #fff;
}

.btn-primary:hover {
  background: #d6923e;
}

.btn-secondary {
  background-color: #b9b2b2;
  color: var(--text-color);
}

.btn-secondary:hover {
  background-color: #ccc;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .reset-app-container {
    height: 100vh;
  }

  .reset-page-container {
    padding: 30px;
    margin: 10px;
  }

  .page-title {
    font-size: 28px;
  }

  .page-subtitle {
    font-size: 16px;
  }

  .input-field {
    width: calc(100% - 30px);
    padding: 12px;
    font-size: 14px;
  }

  .btn-primary,
  .btn-secondary {
    padding: 12px 0;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .reset-app-container {
    height: 100vh;
  }

  .reset-page-container {
    padding: 20px;
    margin: 5px;
  }

  .page-title {
    font-size: 24px;
  }

  .page-subtitle {
    font-size: 14px;
  }

  .input-field {
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 12px;
  }

  .btn-primary,
  .btn-secondary {
    padding: 10px 0;
    font-size: 12px;
  }
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
  text-align: left;
}