.feadback_stats_container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #ffffff;
  background-image: url('../../assets//background_image.png');
  background-size: cover;
  /* background-position: center; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  padding: 60px;
}

.upper-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px;
  width: 600px;
}

.text-overlay {
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 8px;
}

.text-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.text-overlay p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.text-overlay ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}

.text-overlay ul li {
  margin-bottom: 10px;
}

.submit-btn {
  background-color: #F6931E;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #e5821a;
}

.card {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  text-align: center;
}

.card h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.card-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .feadback_stats_container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    height: auto;
    /* Adjust height for smaller screens */
  }

  .upper-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }

  .text-overlay {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);
    margin-top: 57px;
  }

  .text-overlay h1 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .text-overlay p {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .text-overlay ul li {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .card {
    width: 90%;
    /* Take up most of the screen width */
    margin: 20px auto;
    padding: 15px;
    box-shadow: none;
    /* Reduce shadow for a flatter mobile design */
  }

  .card h2 {
    font-size: 1.5rem;
  }

  .card p {
    font-size: 0.9rem;
  }

  .card-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}



/* statistics.css */
.statistics-container {
  font-family: 'Arial', sans-serif;
  color: #4a007e;
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
  height: 100vh;
  /* Ensure the page takes the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-stats {
  height: 100%;
}

.header {
  margin-bottom: 40px;
}

.header h1 {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
}

.content {
  position: relative;
}

.map-background {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/map.png');
  /* background-size: cover; */
  background-position: center;
  opacity: 10;

    background-size: contain;
      background-repeat: no-repeat;
}

/* .stats-cards {
  position: absolute;
  top:20%;
  left: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 30%;
  max-width: 1200px;
} */
 .stats-cards {
   position: absolute;
   top: 20%;
   left: 50%;
   /* Centers the element horizontally */
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 30px;
   width: 30%;
   max-width: 1200px;
 }

.stats-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 50px;
  color: #6a1b9a;
}

.card-stats {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-stats:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.card-stats h2 {
  font-size: 24px;
  font-weight: bold;
  color: #6a1b9a;
  margin-bottom: 10px;
}

.card-stats p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.card-stats span {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}
/* General container adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .statistics-container {
    padding: 20px;
    text-align: center;
  }

  .stats-heading {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .map-background {
    background-size: cover;
    background-position: center;
    height: auto;
    padding-bottom: 20px;
  }

  .stats-cards {
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    grid-template-columns: 1fr;
    gap: 20px;
    width: 90%;
  }

  .card-stats {
    padding: 15px;
  }

  .card-stats h2 {
    font-size: 20px;
  }

  .card-stats p {
    font-size: 14px;
  }

  .card-stats span {
    font-size: 12px;
  }
}

/* Testimonials.css */
.testimonials {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
}

.testimonials h1 {
  color: #4B006E;
  margin-bottom: 20px;
  font-weight: bolder;
}

.testimonials p {
  color: #333;
  margin: 10px 0;
}

.testimonial {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.testimonial-content {
  text-align: left;
}

.testimonial-content h2 {
  margin: 0;
  font-size: 1.2em;
  color: #5a2d82;
}

.stars {
  color: #ffb400;
}

.date {
  color: #999;
  font-size: 0.9em;
}


/* feedback.css */
.feedback-form-container {
  background-color: #4b0082;
  color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title-section {
  text-align: center;
  margin: 30px 0;
}

.title-section h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.title-section p {
  font-size: 16px;
  line-height: 1.5;
}

.form {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.form p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.6;
}

form {
  display: flex;
  flex-direction: column;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.feedback-input,
.feedback-text-area {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.feedback-text-area {
  resize: vertical;
  height: 100px;
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.rating span {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
}

.rating button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 5px;
}

.rating .on .star {
  color: gold;
  font-size: 20px;
}

.rating .off .star {
  color: #ccc;
  font-size: 20px;
}

.consent {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 12px;
  line-height: 1.4;
}

.consent input {
  margin-right: 10px;
  margin-top: 3px;
}

button[type="submit"] {
  background-color: #4b0082;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #3a0066;
}

/* Common styles for messages */
.message {
  font-size: 1rem;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
  max-width: 100%;
  display: none;
}

/* Success message */
.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Error message */
.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.formPara {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.lastBannerSection {
  width: 100%;
}

.lastBannerSectionImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.noFeedBack {
  font-size: 1.2rem;
  font-weight: bold;
  color: #3a0066;
  margin-top: 20px;
}

