.policy-part-one {
  margin-top: 2rem;
}

.policy_title {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 1rem 0;
  color: #f6931e;
  font-style: bold;
}

.policy-btn-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.policy_heading {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: .5rem;
  font-weight: bold;
}

.policy-button {
  margin: 8px 8px 0 0;
  padding: 10px 20px;
  background-color: #f6931e;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8rem;
}

.active-policy {
  background-color: #c97004;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 4px;
}