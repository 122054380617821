.container1 {
  /* display: grid; */
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: 33% 33% 33%;
    grid-gap: .2rem
  }
}

.plainText {
  width: 150px;
}

.plainTextEnd {
  width: 170px;
}

.ropContainer {
  border: 3px solid #F6931E !important;
  background-color: #cccccc52 !important;
}

.logo-container {
  display: flex !important;
  margin-right: 10px !important;
}

.head-img {
  width: 95px !important;
}

.btn-sec {
  background-color: #41025F !important;
  border: 2px solid #F6931E !important;
  padding: 0.3rem .8rem;
  border-radius: 5px;
  margin-top: 5px;
}

.btn-sec:hover {
  background-color: #560756;
  color: #fff;
  cursor: pointer;
}

.sec {
  font-size: 13px;
  margin-top: 5px;
  font-weight: 400;
  color: #000000 !important;
}

.sec-two {
  font-size: 10px;
  margin-bottom: 7px;
}

.price-btn {
  width: 140px !important;
  padding: 1rem .5rem !important;
  background-color: #41025F !important;
  border: 2px solid #F6931E !important;
}

/* detailModal */

.detailModal {
  display: flex;
  flex-direction: column;
  background-color: #F1F1F1;
  border-radius: 0 0 5px 5px;
  padding: 1.3rem 1rem;
  width: 90%;
  height: auto;
  margin: 0 auto;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.detailModal__header {
  display: grid;
  grid-template-columns: 0.8fr 1fr 0.3fr;
  gap: 2rem;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  line-height: 22px;
}

.lastDetailBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lastDetailBox p {
  margin-left: 4rem;
  cursor: pointer;
  font-weight: bold;
  color: #41025F;
  text-decoration: 1.5px underline #41025F;
}

.detailsCheckBoxFirst {
  margin-top: 10px;
}

.detailsCheckBoxLast {
  margin-top: 5px;
}

.specialPlan {
  /* display: flex;
  justify-content: flex-start; */
  margin-top: 10px;
}

.specialPlan_span {
  background-color: #42025f88;
  padding: 8px 10px;
  border-radius: 27px;
  margin-right: 10px;
  color: #41025F;
  font-weight: bold;
  border: 1px solid #f6951eaa;
}

.detailModal__header__btn {
  padding: 0.3rem 0.3rem;
  width: 100px;
  background-color: #41025F;
  border: 2px solid #F6931E;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

}

.check {
  cursor: pointer;
}

.checkText {
  cursor: pointer;
}

.checkText-mobile {
  cursor: pointer;
  font-size: 13px;
}



/* garbage css also */

.introInput_two {
  border-radius: 5px;
  border: 1px solid #959595;
  width: 250px !important;
  margin-bottom: 4px;
  margin-top: 10px;
}

.introInput_two:focus {
  outline: none;
  border: 1px solid #959595;
  box-shadow: 0 0 5px #959595;
}

.introBtn_two {
  position: absolute;
  top: 55%;
  right: 4px;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #F6931E;
  border-radius: 5px;
  width: 30px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #41025F;
}

.introSpan_two {
  margin-top: 4px;
  color: #F6931E;
  color: #fff;
}

.error-containerA {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 2.3rem;
  z-index: 1;
  align-items: flex-end;
  top: -2.8rem;
}

.error-msg-containerA {
  padding: .5rem 1rem;
  /* background: linear-gradient(to left, #ED4168, #FFB25A); */
  background-color: #F6931E;
  border-radius: .6rem;
  color: #fff;
  font-weight: 900;
}

.errArrowA {
  width: 0;
  height: 0;
  border-top: 10px solid #F6931E;
  border-left: 10px solid transparent;
  margin-right: .5rem;
}

.error-iconA {
  width: 20px;
  margin-top: .4rem;
}

.annual-income_two {
  height: 50px;
}

.annualText {
  font-size: 10px;
  color: #F6931E !important;
  margin-top: -1px !important;
}

.addonsInput {
  border-radius: 5px;
  border: 1px solid #959595;
  width: 130px !important;
  margin-bottom: 4px;
  margin-top: 5px;
  margin: 5px 0 5px 7px;
  padding: 2px 5px;
}

.addonsInput::placeholder {
  color: #959595;
  font-size: 13px;
}

.addonsInput:focus {
  outline: none;
  border: 1px solid #41025F;
  box-shadow: 0 0 5px #959595;
}

.addonsButton {
  background-color: #fff;
  border: 1px solid #F6931E;
  border-radius: 5px;
  cursor: pointer;
  background-color: #41025F;
  padding: 2px 8px;
  margin-left: 20px;
}

.newTooltip {
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  color: #f58d0e;
}

.newLabel {
  display: flex;
  font-weight: bolder;
  color: #f58d0e;
  margin-left: 5px;
  font-size: 12px;
}

.paraAdd {
  font-size: 12px;
  color: #F6931E !important;
  margin-top: -10px !important;
  font-weight: bold;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* CSS FOR MOBILE SCREEN SIZE */
.mainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #f1f1f1;
  padding-bottom: 1rem;
}

/* .sorting {

  padding: 10px 10px 0 0
} */

.details_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
  /* margin-bottom: 1rem; */
  border-radius: 6px 6px 0 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
}

.quoteDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 10px;
  font-size: 13px;
}

.footer_btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.cardBuyBtn {
  padding: 0.3rem 0.6rem;
  background-color: #41025F !important;
  border: 1px solid #F6931E !important;
  border-radius: 6px;
}

.addonsCard {
  display: flex;
  flex-direction: column;
  width: 90%;
  /* margin-bottom: 10px; */
  /* background-color: #fff; */
  background-color: #F1F1F1;
  border-radius: 0 0 6px 6px;
  padding: 8px;
  transition: max-height 0.10s ease-in-out;
}

.addons_input {
  border-radius: 5px;
  border: 1px solid #959595;
  width: 120px !important;
  margin-bottom: 4px;
  margin-top: 5px;
  margin: 5px 0 5px 7px;
  padding: 2px 5px;
}

.addons_input_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.addonsCardBtn {
  font-size: 13px;
  height: 35px;
  width: 170px;
  margin-right: 20px;
  padding: 0.3rem 0.6rem;
  background-color: #41025F !important;
  border: 1px solid #F6931E !important;
  border-radius: 6px;
  margin-left: 20px;
}

.mobile_head-img {
  width: 90px !important;
  height: 45px;
  margin-right: 10px;
}

.mobile_head-name {
  font-size: 13px;
}

.logo-container3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.quoteData {
  font-weight: bold;
  font-size: 12px;
}

.twoMoreBtn {
  text-decoration: underline;
  width: 80px;
}

.errorAddons {
  margin-top: 10px;
}

.noteForLumpsumIncome {
  margin-top: 15px;
}

.newLabelForMobile {
  display: flex;
  font-weight: bolder;
  color: #41025F;
  margin-left: 5px;
  font-size: 9px;
  margin-top: 1px;
}


.tataSrpNotePara {
  font-size: 14px;
  color: #F6931E !important;
  margin-top: 10px !important;
  font-weight: bold;
}