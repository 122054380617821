.nominee-details-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.nominee-details-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.nominee-details-section h4 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

/* GRID LAYOUT */
.nominee-details-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Two columns */
  grid-gap: 20px;
  /* Space between items */
  align-items: start;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #4b006e;
  box-shadow: 0 0 5px rgba(75, 0, 110, 0.5);
}

input[disabled] {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

button.add-btn {
  background-color: #4b006e;
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

button.add-btn:hover {
  background-color: #2d0043;
}

/* TABLE FOR NOMINEE DETAILS */
.nominee-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
}

.nominee-table th,
.nominee-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.nominee-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.nominee-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.nominee-table tr:hover {
  background-color: #f1f1f1;
}

.nominee-table td button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.nominee-table td button:hover {
  background-color: #d32f2f;
}