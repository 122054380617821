.about_container {
  /* padding: 1rem 3rem 0 3rem; */
  width: 75%;
  margin: 0 auto;
}

.logo-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.logo-banner {
  width: 100%;
  height: 500px;
  margin: 3rem 0;
}

.logo-img {
  width: 100%;
  height: 100%;
}

.about_card {
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 2rem 0 1rem 0;
  font-weight: bold;
  color: #550c5b;
}

.about-sub-text {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.about_details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 2rem 0;
}

.details-title {
  font-weight: bold;
  margin-bottom: .6rem;
}

.details_offer_section {
  font-size: 1rem;
  line-height: 1.5rem;
}

.what_we_offer {
  font-weight: bold;
  margin: 2rem 0 .6rem 0
}

.spaceBottom {
  margin-bottom: 1rem;
}

.management_team {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0;
}

.management_team div {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}

.new-director-img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  max-width: 100%;
}

.management_team_title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  color: #f6931e;
}

.about-director-name {
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1rem;

}

.about-director-designation {
  font-style: italic;
  margin-bottom: 0.5rem;
  font-size: .9rem;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .about_details {
    flex-direction: column;
  }

  .mobileSpace {
    padding-top: 3rem;
  }

  .logo-banner {
    height: 300px;
  }

  .about_card {
    margin: 10px 0;
  }

  .management_team {
    flex-direction: column;
    align-items: center;
  }

  .management_team div {
    margin: 10px 0;
  }

}