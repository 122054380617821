.prof-icon {
    top: 14px;
    right: 26px;
}

.edit_docs {
    height: 18px !important;
    width: 18px !important;
    margin: 2px 0 0 8px !important;
  }
  
  .edit_div {
    position: relative;
  }
  .edit_div input {
    opacity: 0;
    width: 24px;
    position: absolute;
    top: 0;
    left: 6px;
    border: 0;
    height: 20px;
  }

  .downloadAllDocuments {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #F6931E;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
  }