.container1 {
  /* display: grid; */
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: 33% 33% 33%;
    grid-gap: .2rem
  }
}