.range,
.range-mobile {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.range button {
  width: 400px;
  border: 1px solid #959595;
  border-radius: 4px;
  background: #fff;
  color: #959595 !important;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  margin-bottom: .2rem;
  cursor: pointer;
  text-align: left;
}

.range button:hover {
  background-color: #F6931E;
  color: #fff !important;
}

.introInput {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #959595;
  width: 400px;
  margin-bottom: 4px;
}

.introInput-mobile {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #959595;
  width: 290px;
  margin-bottom: 4px;
}

.introInput:focus {
  outline: none;
  border: 1px solid #959595;
  box-shadow: 0 0 5px #959595;
}

.introBtn {
  position: absolute;
  top: 45%;
  right: 4px;
  transform: translateY(-50%);
  background-color: #fff;
  border: 0;
  border-radius: 5px;
  width: 30px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #F6931E;
}

.introSpan {
  margin-top: 4px;
  color: #F6931E;
  color: #fff;
}

/* .introBtn:hover {
  background-color: #F6931E;
} */

/* .introSpan:hover {
  color: #fff;
} */

/* line for the card after the <p> */
.line {
  border-top: 1px solid #ccc;
  margin: 0 0 10px 0;
  width: 65rem;
}

.carousel-text {
  font-weight: 400 !important;
  color: #00000099;
}

.line-text {
  font-size: 1.3rem;
  font-weight: bolder;
}

.error-containerA {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 2.3rem;
  z-index: 1;
  align-items: flex-end;
  top: -2.8rem;
}

.error-msg-containerA {
  padding: .5rem 1rem;
  /* background: linear-gradient(to left, #ED4168, #FFB25A); */
  background-color: #F6931E;
  border-radius: .6rem;
  color: #fff;
  font-weight: 900;
}

.errArrowA {
  width: 0;
  height: 0;
  border-top: 10px solid #F6931E;
  border-left: 10px solid transparent;
  margin-right: .5rem;
}

.error-iconA {
  width: 20px;
  margin-top: .4rem;
}

.annual-income {
  height: 60px;
}

.annualText {
  font-size: 10px;
  color: #F6931E !important;
  margin-top: -1px !important;
}

/* Mobile Screen */
.range-mobile button {
  width: 290px;
  border: 1px solid #959595;
  border-radius: 4px;
  background: #fff;
  color: #959595 !important;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  margin-bottom: .2rem;
  cursor: pointer;
  text-align: left;
}

.carousel-text-mobile {
  font-weight: 400 !important;
  color: #00000099;
  font-size: 11px;
}

.line-text-mobile {
  font-size: 1.1rem;
  font-weight: bolder;
}