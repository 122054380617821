.card-title {
    font-size: 20px;
    opacity: .7;
    font-weight: 700;
    margin-left: 1rem !important;
}

.sub-head {
    font-size: 12px;
}

.data {
    font-weight: 700;
    font-size: 24px;
}

.positive {
    color: green !important;
}

.negative {
    color: red !important;
}

.percent {
    color: #FF3E1D;
    font-size: 13px;
    font-weight: 600;
}


.arrow-icon {
    margin-right: 5px;
}

.arrow-up {
    color: green !important;
}

.arrow-down {
    color: red !important;
}

.arrow-equal,
.equal {
    color: #bf6f0d !important;
}

.selected-icon {
    filter: invert(31%) sepia(33%) saturate(4609%) hue-rotate(349deg) brightness(101%) contrast(102%);
}

.profit {
    filter: invert(71%) sepia(21%) saturate(1005%) hue-rotate(116deg) brightness(84%) contrast(83%);
}

.profit-txt {
    color: #46B39A !important;
}

.mini-title {
    color: #333333;
    opacity: .7;
    font-size: 15px;
    margin-top: .7rem !important;
    margin-bottom: .5rem !important;
    line-height: 1.1;

}

.view-details {
    color: #41015F !important;
    font-size: 15px;
    text-decoration-line: underline;
    margin-top: .5rem !important;
}