.main7 {
    width: 100%;
    height: 94.1vh;
    display: flex;
    justify-content: center;
    background-color: rgba(38, 37, 37, 0.849);
}

.Pos {
    margin-top: 3rem;
    display: flex;
    align-items: center;

    flex-direction: column;

    height: 75vh;
    width: 35%;
    border: 1px solid rgba(0, 0, 0, 0.142);
    border-radius: 8px;

    background-color: white;
    text-align: center;

}

.Pos1 {


    width: 60%;
    height: 40vh;
}

.Pos1 img {


    width: 80%;
    height: 90%;

}

.Pos2 p {
    margin-top: 1rem;
    color: rgb(26, 216, 26);
    font-weight: 600;
    font-size: 20px;
}

.Pos3 p {
    /* line-height: 1rem; */
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.563);
    font-weight: bold;
    font-size: 15px;
    /* font-family: sans-serif; */
    /* font-size: 15px; */
    /* text-align: center; */
}









.btn8 button {

    padding: 10px 45px;
    margin-top: 20px;
    background-color: rgba(24, 23, 21, 0.209);
    border: none;
    color: rgba(44, 35, 35, 0.716);
    font-size: 15px;
    font-weight: 700;
    /* margin-left:40% ; */
    border-radius: 8px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}