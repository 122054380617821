.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F1F1F1;
  padding: 25px 20px 10px 20px;
  border-radius: 10px;
  position: relative;
}

.custom-dropdown {
  border: none;
  background: none;
  padding: 0.4em 0 0.4em 0.4em;
  cursor: pointer;
  margin-right: 40px;
  font-size: 13px;
}

.custom-dropdown-mobile {
  border: none;
  background: none;
  padding: 0.4em 0 0.4em 0.4em;
  cursor: pointer;
  font-size: 12px;
}


.custom-dropdown-mobile:focus {
  outline: none;
}

.custom-dropdown:focus {
  outline: none;
}

.labIcon {
  margin-right: 20px;
  margin-bottom: 8px;
}

.filterOne,
.filterTwo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 5px 8px 5px;
  border-radius: 6px;
  width: 100%;
  background-color: #F1F1F1;
  position: relative;

}

.filterTwo {
  margin-top: 10px;
}