.numberField::-webkit-outer-spin-button,
.numberField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.numberField {
  -moz-appearance: textfield;
  /* Firefox */
}