.footer {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    width: 100%;
}

.upper {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 1rem;
    /* height: 15vh; */
    background-color: #F2F2F2;

}

.upper span {
    margin: 0 1rem;
}

.middle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #E4E4E4;
}


.footRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headLower {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.headUpper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.headUpper h3 {
    margin-bottom: 1.5rem;
    font-weight: 900;
    font-size: 1.4rem;
}



.footMiddle {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 2.2rem;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
}

.footMiddle h3 {
    font-weight: 900;
    font-size: 1.4rem;
}

.footleft {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    line-height: 2rem;

}


.footleft p {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.541);
}

.tnc {
    text-align: justify;
    font-size: .85rem !important;
}

.last {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
    background-color: #ffffff;
}

.footWrapper {
    display: flex;
}

.footWrapper p {
    margin: auto 1.75rem;
}

.footer-links {
    cursor: pointer;
    /* line-height: 2.5rem; */
}

.link-fac {
    display: flex;
    column-gap: 2rem;
}

@media screen and (max-width:1000px) {
    .middle {
        padding-top: 5%;
        display: block;
    }

    .middle .footleft p {
        font-size: 15px;
    }

    .last {
        margin-top: 50px;
        padding-bottom: 50px;
    }

    .upper {
        padding: 1rem;
    }
}

@media screen and (max-width:840px) {
    .upper {
        display: flex;
        flex-direction: column;

    }

    .footWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footWrapper p {
        margin: 1rem;
    }

    .footMiddle {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.contact-p span {
    font-weight: 700;
}

.footWrapper p:hover {
    cursor: pointer;
}

/* ------------------New Footer ---------------- */

ul {
    list-style: none;
}

.pointer {
    cursor: pointer;
}

.marginBottom {
    margin-bottom: .8rem;
}

.marginTop {
    margin-top: 10px;
}

.colorRed {
    color: #b93333;
    font-size: 14px;
}

.narrow-hr {
    width: 98.5%;
}

.bold {
    font-weight: 700;
}

.mainContainer {
    font-family: inter !important;
}

.FooterSecOne {
    padding: 2rem 0;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
}

.FooterSecOne ul {
    list-style: none;
}

.boldAndSpace {
    font-weight: 700;
    margin-bottom: 1rem;
}

.footerLogo {
    margin-bottom: 1rem;
}

.paraTwo {
    margin-top: 1rem;
}

.secOnePartOne {
    width: 20%;
    padding-left: 3rem;
    padding-right: 3rem;
}

.width20 {
    width: 20%;
}



.FooterSecTwo {
    padding: 0 0 2rem 0;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
}

.iconSpace {
    margin-left: .5rem;
}

.social {
    margin-top: .5rem;
    margin-bottom: 2rem;
}

.footer-inputBox {
    display: flex;
    align-items: center;
}

.footer-inputBox input {
    width: 100%;
    padding: .3rem;
    border: 1px solid #000000;
    border-radius: 5px;
    outline: none;
}

.footer-inputButton {
    background-color: #000000;
    color: #ffffff;
    padding: .4rem .5rem;
    border: none;
    border-radius: 5px;
    margin-left: .1rem;
    cursor: pointer;
}

.textDeco {
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 5px;
}

.secTwoPartOne {
    width: 20%;
    padding-left: 3rem;
    padding-right: 3rem;
}

.PartTwo {
    margin-top: 1rem;
    font-weight: 700;
}

.FooterSecThree {
    background-color: #f2f2f2;
    padding: 2rem 0;
}

.FooterSecFour {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-content: center; */
}

.secThreePadding {
    padding-left: 4.3rem;
    padding-right: 3rem;
}

.paraPartFour {
    font-size: 14px;
    margin-bottom: 10px;
}

/* font size */
.fontSmall {
    font-size: 1.3rem;
    line-height: 2.2rem;
}

.fontMedium {
    font-size: 1.4rem;
    line-height: 2.2rem;
}

.fontLarge {
    font-size: 1.6rem;
    line-height: 2.2rem;
}

/* Media Queries for Tablet */
@media screen and (max-width: 1024px) {
    .mainContainer {
        font-size: 15px;
    }

    .FooterSecOne,
    .FooterSecTwo,
    .FooterSecThree,
    .FooterSecFour {
        flex-direction: column;
        align-items: center;
        text-align: start;
    }

    .secOnePartOne,
    .secOnePartTwo,
    .secOnePartThree,
    .secOnePartFour,
    .secTwoPartOne,
    .secTwoPartTwo,
    .secTwoPartThree,
    .secTwoPartFour {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    /* .PartOne p {
        justify-content: center;
    }

    .contactData p {
        justify-content: center;
    }

    .contactDataTwo p {
        justify-content: center;
    } */

    .boldAndSpace {
        font-size: 18px;
    }
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
    .mainContainer {
        font-size: 13px;
    }

    .FooterSecOne,
    .FooterSecTwo,
    .FooterSecThree,
    .FooterSecFour {
        padding: 1rem 1.5rem 1rem 3.2rem;
    }

    /* .PartOne p {
        justify-content: center;
    }

    .contactData p {
        justify-content: center;
    }

    .contactDataTwo p {
        justify-content: center;
    } */

    .boldAndSpace {
        font-size: 18px;
    }
}

.contactData a {
    color: #000000;
}

.contactDataTwo a {
    color: #000000;
}

.followUs {
    margin-top: 2rem;
}