/* InsuranceSteps.css */

.insurance-steps-container {
  display: flex;
  overflow-x: auto;
  /* Enable horizontal scrolling if needed */
}

.icon-step-container {
  display: flex;
  flex-wrap: nowrap;
  /* Ensure steps stay in one line */
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
  /* Adjust width as needed */
  margin-right: 10px;
  /* Adjust space between steps */
}

.step .icon {
  font-size: 24px;
}

.step-description {
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
}

.step-title {
  margin-top: 3px;
  text-align: center;
  font-size: 12px;
}

/* Style for the button */
.step button {
  background-color: #f6931e;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: .8rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.step button:hover {
  background-color: #d97a00;
  /* Darker shade when hovered */
}