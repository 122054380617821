/* FAQPage.css */

/* Desktop Styles */
.faq-page {
  padding: 20px;
  width: 65%;
  margin: 0 auto;
}

.faq-buttons {
  display: flex;
  margin-bottom: 20px;
}

.faq-button {
  flex: 1;
  padding: 10px;
  background-color: #f2f2f2;
  color: #000 !important;
  border: none;
  cursor: pointer;
  outline: none;
}

.faq-button.active {
  background-color: #F6931E;
  color: #fff;
}

.faq-list .faq {
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.faq-list .faq.active {
  border: 1px solid #ccc;
}

.question {
  padding: 10px;
  cursor: pointer;
  background-color: #f2f2f2;
  font-weight: bold;
}

.answer {
  padding: 10px;
  display: none;
}

.faq.active .answer {
  display: block;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .faq-page {
    width: 90%;
    padding: 15px;
  }

  .faq-buttons {
    flex-direction: column;
  }

  .faq-button {
    margin-bottom: 10px;
    padding: 12px;
    font-size: 16px;
  }

  .faq-button.active {
    font-size: 18px;
  }

  .question {
    font-size: 16px;
    padding: 8px;
  }

  .answer {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .faq-page {
    width: 100%;
    padding: 10px;
  }

  .faq-button {
    padding: 10px;
    font-size: 14px;
  }

  .faq-button.active {
    font-size: 16px;
  }

  .question {
    font-size: 14px;
  }

  .answer {
    font-size: 12px;
  }
}