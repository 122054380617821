/* HealthInsuranceTable.css */
.insurance-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.insurance-table th,
.insurance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.buy-now-button {
  background-color: #4b006e;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #541671;
  }
}