/* Media Container */
.media-container {
  width: 75%;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 1rem;
}


/* Media Heading */
.media-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
  color: #f6931e;
}

/* Media Banner */
.media-banner {
  width: 100%;
  height: 100%;
}

.media-img-sec {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 3rem 0;
}

.media-button {
  position: absolute;
  top: 75%;
  left: 9%;
  padding: 10px 15px;
  background-color: #800888;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}


.corner-heading {
  margin-top: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #f6931e;
}

/* Subscribe Section */
.subscribe-section {
  /* text-align: center; */
  margin: 0 0 4rem 0;
}

.subscribe-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.subscribe-button {
  padding: 10px 20px;
  background-color: #800888;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 2rem;
}

.subscribe-button:hover {
  background-color: #c07315;
}

/* Download Section */
.download-section {
  margin-bottom: 40px;
}

.download-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.downloadable-resources {
  display: flex;
  gap: 20px;
}

.downloadable-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 300px;
  margin-top: 20px;
}

.downloadable-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.downloadable-card p {
  font-size: 16px;
  margin-bottom: 20px;
}

.download-button {
  padding: 10px 20px;
  background-color: #800888;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #a96716;
}

.social-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.social-icons {
  font-size: 24px;
}

.social-icons a {
  margin-right: 10px;
  color: #f6931e;
}

.social-icons a:hover {
  color: #e67e22;
}

.subscribe-para-span a {
  color: #000;
}