.main-container {
    width: 100%;
    margin-top: 25px;
}

p, h4 {
    margin: 0;
}


.flex {
    display: flex;
}

.apart {
    justify-content: space-between;
}

.c-gap {
    column-gap: 1rem
}

.align-center {
    align-items: center;
}

.column {
    flex-direction: column;
}

.j-center {
    justify-content: center;
}

.j-between {
    justify-content: space-between
}

.f-grow-1 {
    flex-grow: 1
}

.mt-1 {
    margin-top: 1.5rem !important;
}

.pr-1 {
    padding-right: 10rem !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.master-title {
    font-weight: 700;
    color: #333333;
    align-self: center;
    font-size: 1.25rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.left {
    /* background-color: #F5F5F9; */
    width: 50%;
}

.right {
    /* background-color: #F5F5F9; */
    width: 50%;
}

.red {
    background: #FF0D0D;
}

.orange {
    background: #FF4E11;
}

.pink {
    background: #FF8E15;
}

.yellow {
    background: #FAB733;
}

.lime {
    background: #B2D3C2;
}

.green {
    background: #32CC32;
}

.selected-tab {
    background-color: #F6931E;
    color: #fff;
    padding: 0.5rem;

}

.master-trow {
    padding: 0.7rem;
    height: 40px;
    width: 80px;
}

.master-trow.varient {
    width: 200px;
}

.master-trow.cc {
    width: 10px;
}

.master-trow.model {
    width: 120px;
}

.master-trow.brand {
    width: 120px;
}

.trow {
    border: 1px solid #ccc;

}


.entries-container {
    background-color: #F6931E29 !important;
    /* border-radius: 8px 8px 0 0; */
    list-style-type: none;
    text-transform: uppercase !important;
    text-align: left;
    font-weight: 600;
    color: #605F5E;
}

.table_body {
    background-color: white;
}

.dropdown-sele {
    width: 180px;
    margin: 0.1rem;
    border: none;
    background: none;
    outline: none;
}

.dropdown-sele.brand {
    width: 100px;
}

.dropdown-sele.model {
    width: 120px;
}

.dropdown-sele.fuel {
    width: 100px;
}

.dropdown-sele.cc {
    width: 80px;
}

.dropdown-sele.variant {
    width: 200;
}

.show-text {
    cursor: pointer;
}

/* .data: {} */

.fit_content {
    width: 100%;
    background-color: #fff !important;
    text-align: center;

    .fit_heading {
        text-align: center;
        border: 1px solid #ccc;
        padding: 0.25rem;
        padding-top: 0.8rem;
    }
}

ul.paginate-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px;
    list-style: none;
    align-items: baseline;
}

ul.paginate-list .paginate-numbers {
    width: 40px;
    height: 40px;
    font-size: 15px;
    background-color: #e4e4e4;
    color: #000 !important;
    border-radius: 50%;
    margin: 3px;
    border: none;
}

button.paginate-button {
    width: 40px;
    height: 40px;
    background-color: #e4e4e4;
    border-radius: 50%;
    border: none;
    align-self: center;
    align-content: center;
}

li.paginate-dots {
    font-size: large;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

span.paginate-icons {
    justify-content: center;
    margin: 12px;
}

ul.paginate-list .paginate-numbers.active {
    background-color: #704176;
    color: #e4e4e4 !important;
}

.search-area {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

form.search-form {
    padding-right: 0.5rem;
}

div.search-container {
    position: relative;
}

svg.search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 1.0rem;
    margin-top: auto;
    margin-bottom: auto;
    color: #a0a0a0;
    left: 0.3rem;
}

/* Base styles */
input.search-input {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 1rem;
    border-radius: 0.175rem;
    height: 30px;
    border: 1px solid #ccc;
    background-color: #fff;
}

/* Focus styles */
input.search-input:focus {
    background-color: #fff;
    border: 1px solid #444;
}

.fit_heading_search {
    text-align: center;
    /* border: 1px solid #ccc; */
    padding: 0.25rem;
    /* padding-top: 0.8rem; */
}

.slect-mappCount {
    display: flex;
    justify-content: space-between;
    margin-right: 0.8rem;
    align-items: baseline;
    align-content: baseline;
}