/* PrashantPage.css */

/* Overall layout and positioning */
.prashant-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f2f2f2;
}

/* Profile section */
.profile {
  text-align: center;
  margin-bottom: 20px;
  max-width: 1000px;
}

.director-img {
  border-radius: 10px;
  width: 300px;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.director-name {
  font-weight: bold;
  font-size: 1.8rem;
  margin: 10px 0px 0px 0px;
}

.director-designation {
  color: #777;
  font-size: 1.3rem;
  font-style: italic
}

.director-designation-title {
  font-size: 16px;
  word-spacing: 1.5px;
}

/* Social links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.social-links a {
  text-decoration: none;
}

.social-links a:hover {
  color: #f6931e;
}

/* Director information */
.director-info {
  text-align: left;
  max-width: 1000px;
}

.director-info h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.director-info h3 {
  font-size: 1.5rem;
  color: #f6931e;
  margin-bottom: 10px;
}

.director-info p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}