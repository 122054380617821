.HomeContainer {
    /* height: 120vh; */
    padding: 4rem;
    /* padding-top: 10rem; */
    /* width: 100%; */
    /* background:url() */
    display: flex;
    background: linear-gradient(to top right, rgb(255, 255, 187), rgb(65, 2, 95));
    /* margin-top: 6.1rem; */
    /* position: ; */


}

.rightwrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 3rem;
    /* padding-top: 2rem; */
    /* background-color: red; */
    /* background:linear-gradient(to  right,   rgba(255, 255, 187, 0.295),rgba(66, 2, 95, 0.322)), url('../../assets/removeBg.png');
    background-repeat: no-repeat;
    background-position: center; */
}

.rightwrapper h1 {
    color: rgb(234, 226, 226);

    /* font-weight: bolder; */
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.rightwrapper p {
    color: rgb(234, 226, 226);
    text-align: center;
    font-size: 1rem;
    /* margin-bottom: .3rem; */
    /* font-weight: bolder; */
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.form-bg-img {
    opacity: 0.8;
    width: 450px;
}

.leftwrapper {
    flex: 1;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;

}

.content {
    /* min-height: 550px; */
    /* width: 75%; */
    background-color: white;
    /* border: 1px solid black; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.11), -5px -5px 10px rrgba(0, 0, 0, 0.116);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: space-around; */
    padding: 1.2rem 3.8rem;
}

.content h1 {
    font-size: 1.5rem;
    font-family: 'calist';
    font-style: italic;
}

.content p {
    color: rgba(0, 0, 0, 0.603);
}

.content .heading1 {
    margin: 0.5rem 0 .6rem;
}

.heading1 h1 {
    font-size: 26px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.815);
}

.gender .selected {
    height: 60px;
    width: 180px;
    border: 1px solid rgba(255, 0, 0, 0.938);
    text-align: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 0, 0, 0.938);
    ;
    /* margin-right: 1rem; */
    cursor: pointer;
}

.gender .unselected {
    height: 60px;
    width: 180px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    text-align: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.342);
    /* margin-right: 1rem; */
    cursor: pointer;
}

.gender {
    display: flex;
    justify-content: space-between;
}

/* .gender.male{
    border: 1px solid rgb(255, 3, 3);
    margin: right 2rem;


} */
.selectedGender {
    margin-right: 1rem;
    font-size: 2rem;
    color: rgba(255, 2, 2, 0.904);
}

.unselectedGender {
    margin-right: 1rem;
    font-size: 2rem;
    color: rgba(3, 3, 3, 0.418);
}







.input {
    display: flex;
    flex-direction: column;
}

.input select {
    /* width: 500px; */
    /* height: 50px; */
    /* padding: 0 20px; */
    padding: 1.4rem;
    border-radius: 5px;
    border: 1.3px solid rgba(0, 0, 0, 0.37);
    background-color: transparent;
    margin-top: 10px;

}




.input input {
    /* width: 500px; */
    /* height: 50px; */
    outline: none;
    margin-top: 10px;
    border-radius: 5px;
    border: 1.3px solid rgba(0, 0, 0, 0.377);
    padding: 20px;
}

/* .input label{
color: rgba(0, 0, 0, 0.349);
} */
.sum {
    margin-top: 15rem;
    position: absolute;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-top: 1rem; */

}

.btn-link:hover {
    cursor: pointer;
}

.btn button {
    width: 222px;
    height: 60px;
    outline: none;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    /* border: 1px solid rgba(0, 0, 0, 0.37); */
    border: none;
    background: linear-gradient(to right, rgb(255 178 90), rgb(237 65 104));


}

.checkbox span {
    margin: 0 3rem 0 1rem;
    color: rgba(0, 0, 0, 0.575);
    top: -0.5rem;
    position: relative;
}

.checkbox {
    accent-color: red;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    /* font-size: 20px; */
}

.checkbox input[type="radio"] {
    /* display: none; */
    font-size: 20px;
    height: 16px;
    width: 16px;
    margin-right: 1rem;
}

.checkbox p {
    margin-right: 20px;
    margin-bottom: 0 !important;
}

@media screen and (max-width:1130px) {
    .HomeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding-top: 8rem; */
    }
}

@media screen and (max-width:1015px) {
    .HomeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8rem;
    }
}

@media screen and (max-width:750px) {
    .gender .selected {

        width: 140px;
    }

    .gender .unselected {
        width: 140px;
    }

    .HomeContainer {
        padding: 1rem;
        padding-top: 8rem;
    }

    .content {
        width: 100%;
        padding: 1.15rem;
    }

    .leftwrapper {
        width: 100%;

    }

    .form-bg-img {
        width: 100%;
    }

    .checkbox {
        display: flex;
        align-items: center;
    }

    .checkbox p {
        margin-left: 10px;
    }

    .radio2 {
        margin-left: 12px;
    }

}

@media screen and (max-width:500px) {
    .heading1 {
        display: none;
    }

    /* .checkbox{
        margin-top: -.7rem;
        margin-bottom: .8rem;
    } */
    .btn-link {
        height: 52px;
        width: 189px;
    }

    .leftwrapper {
        margin-top: -.4rem;
    }
}

.selectall {
    margin-top: 1rem !important;
}

.large-select .ant-select-selector {
    padding: 16px 20px !important;
    font-size: 15px;
    line-height: 1.5;
    height: 60px !important;
    border-radius: 5px !important;
    border: 1.3px solid rgba(0, 0, 0, 0.377) !important;
}

.ant-select-selection-item {
    color: rgba(0, 0, 0, 0.377) !important;
}


.ant-picker-header button {
    color: #F6931E !important;
}

.datePicker {
    height: 60px !important;
    border-radius: 5px !important;
    border: 1.3px solid rgba(0, 0, 0, 0.377) !important;
    padding: 20px !important;
    margin-top: 10px !important;
}

.form-group {
    position: relative;
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.floating-label {
    position: absolute;
    top: 60%;
    left: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.1s ease-out;
    font-size: 14px;
    color: #999;
    z-index: 5;
    background-color: white;
    padding: 0 5px;
}

.floating-label-up {
    top: 16px;
    font-size: 12px;
    color: #555;
}

.selectall {
    z-index: 2;
    width: 100%;
}

.error-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 1.9rem;
    z-index: 1;
    align-items: flex-end;
    top: -2.5rem;
}

.error-msg-container {
    padding: .5rem 1rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    color: #fff;
    font-weight: 900;
}

.errArrow {
    width: 0;
    height: 0;
    border-top: 10px solid #F6931E;
    border-left: 10px solid transparent;
    margin-right: .5rem;
}

.error-icon {
    width: 20px;
    margin-top: .4rem;
}

/* Hide the spinner for number input */
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .no-spinner { */
/* Override the default appearance */
/* -moz-appearance: textfield; */
/* } */

.sumAss {
    margin-bottom: auto.3rem;
}

.from-text {
    /* font-family: inter; */
    font-size: 1.2rem !important;
    font-weight: 600;
    letter-spacing: .7px;
    line-height: 1.7rem;
    color: #ffffff !important;

}

.left-side {
    height: 65px;
}

.left-sum-text {
    text-align: start;
    font-size: 11px;
    color: #F6931E !important;
    margin-top: -10px;
    margin-left: 10px !important;
}