/* CSS for the table-like structure */
.table-container {
  display: table;
  width: 100%;
  margin-top: 2rem;
}

.health-banner-image {
  object-fit: cover;
  border-radius: 5px;
  margin: 2rem 0;
}

.health-banner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.health-container-heading {
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  color: #f6931e;
  font-style: bold;
  text-align: center;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
}

.table-cell p {
  margin: 0;
}

/* Alternating row colors (optional) */
.table-row:nth-child(even) {
  background-color: #f9f9f9;
}



/* health */
.health-container {
  width: 75%;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
}

.health-part-one {
  display: flex;
}

.health-column {
  flex: 1;
  padding: 0 10px;
}

.health-btn-container {
  margin-top: 2rem;
}

.health-button {
  margin: 8px 8px 0 0;
  padding: 10px 20px;
  background-color: rgb(28, 132, 28);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8rem;
}

.health-button:hover {
  background-color: rgb(0, 102, 0);
}

/* health part two */
.health-part-two {
  margin-top: 2rem;
}

.health-part-two p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #f6931e;
  font-style: bold;
}

.health-part-three p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
  color: #f6931e;
  font-style: bold;
}

.health-part-four p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #f6931e;
  font-style: bold;
}

.health-part-five p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #f6931e;
  font-style: bold;
}

.health-part-six p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 2.5rem 0 1.5rem 0;
  color: #f6931e;
  font-style: bold;
}

.health-part-seven p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 2.5rem 0 1.5rem 0;
  color: #f6931e;
  font-style: bold;
}

.health-banner {
  width: 100%;
  height: 500px;
  margin: 3rem 0;
}

.health-banner-image {
  width: 100%;
  height: 100%;
}