/* Styling for the card */
.card {
  border: 1px solid #f6931e;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.card p {
  font-size: 1rem;
  color: #555;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* .career_banner {
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
} */

/* Path: src/pages/careers/career.js */

.careerContainer {
  width: 75%;
  margin: 0 auto;
  padding: 0 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 4rem;
}

.career_heading {
  text-align: center;
  margin: 30px 0;
  font-weight: bold;
  font-size: 2rem;
  color: #f6931e
}

.career_heading_small {
  font-size: 1.4rem;
  margin: 40px 0 10px 0;
  color: #f6931e;
  font-weight: bold;
}

.joinTeam p {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: bold;
  margin-bottom: 20px;
}

.jobOpenings {
  margin: 20px 0;
}

.whyJoin {
  margin: 20px 0;
}

.submitResumeForm {
  margin: 20px 0;
}

.submitResumeForm form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.submitResumeForm input,
.submitResumeForm button {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #f6931e;
  border-radius: 5px;
}

.submitResumeForm input[type='text'],
.submitResumeForm input[type='email'] {
  flex: 3;
  margin-right: 10px;
}

.submitResumeForm button {
  flex: 1;
  background-color: #f6931e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitResumeForm button:hover {
  background-color: #d07f1b;
}

.diversity {
  margin: 30px 0 0 0;
}

.joinUsLast {
  margin: 20px 0 10px 0;
  font-weight: bold;
}

.socialMedia {
  margin: 20px 0;
}

.workwithus-footer {
  margin: 20px 0;
}

.work-with-us-contact {
  width: 70%;
  margin: 4rem 0;
  padding: 2rem;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #f6931e;
}

.work-media-data {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 3rem 0;
}

.career_banner {
  width: 100%;
  height: 100%;
}

.work-media-button {
  position: absolute;
  top: 85%;
  left: 9%;
  padding: 10px 15px;
  background-color: #800888;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.whyJoin p {
  margin-bottom: 2rem;
  font-size: 1rem;
}

.wwu {
  margin: 2rem 0;
  background-color: #f2f2f2;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #f6931e;
}

/* .pos-wwu-button {
  padding: 10px 15px;
  background-color: #f6931e;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
  margin: 20px 0;
  width: 180px;
}

.pos-wwu-button:hover {
  background-color: #db8924;
  color: #fff;
} */


/* .pos-data-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: center;
} */

/* .simpli_border_card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #f6931e;
}

.pos-data-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
} */

/* .pos-data-card p {
  font-size: 1rem;
}

.pos-data-card button {
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
} */

/* .rejected-reason {
  font-weight: bold;
  color: red;
  font-size: 1.1rem;
} */

.statusPending {
  color: #f6931e;
  font-weight: bold;
  font-size: 1.3rem;
}

/* Base styles (apply to all screen sizes) */

.pos-data-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: center;
}

.simpli_border_card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #f6931e;
}

.pos-data-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.pos-data-card p {
  font-size: 1rem;
}

.pos-wwu-button {
  padding: 10px 15px;
  background-color: #f6931e;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
  margin: 20px 0;
  width: 180px;
}

.pos-wwu-button:hover {
  background-color: #db8924;
  color: #fff;
}

.rejected-reason {
  font-weight: bold;
  color: red;
  font-size: 1.1rem;
}

.statusPending {
  color: #f6931e;
  font-weight: bold;
  font-size: 1.3rem;
}

/* Media queries for tablets (up to 768px wide) */

@media (max-width: 768px) {
  .careerContainer {
    width: 90%;
    padding: 0 1rem;
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .pos-data-card {
    width: 90%;
    padding: 15px;
    margin: 15px 0;
  }

  .pos-data-card h2 {
    font-size: 1.3rem;
  }

  .pos-data-card p {
    font-size: 0.95rem;
  }

  .pos-wwu-button {
    width: 160px;
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}

/* Media queries for mobile devices (up to 480px wide) */

@media (max-width: 480px) {
  .careerContainer {
    width: 100%;
    padding: 0 1rem;
  }
  .cards-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .pos-data-card {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }

  .pos-data-card h2 {
    font-size: 1.2rem;
  }

  .pos-data-card p {
    font-size: 0.9rem;
  }

  .pos-wwu-button {
    width: 140px;
    padding: 8px 10px;
    font-size: 0.85rem;
  }

  .rejected-reason {
    font-size: 1rem;
  }

  .statusPending {
    font-size: 1.2rem;
  }
}