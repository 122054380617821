.menuText {
  height: 80px;
  width: 100%;
  background-color: #F1F1F1;
  border-radius: 10px 10px 0px 0px;
}

.textOne {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}

/* ViewBar.css */

.textOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainText {
  flex: 1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .mainText {
    text-align: left;
  }
}

.checkdiv {
  display: flex;
  align-items: center;
}

.check {
  width: 15x;
  height: 15px;
  margin-right: 10px;
}

.check .checkText {
  font-size: 14px;
  margin-left: 10px;
}

.sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #F1F1F1;
  border-radius: 6px;
  margin-top: 10px;
}

.sort-item {
  display: inline-flex;
  align-items: center;
}

.tabletContainer {
  margin-top: 7rem;
}