.upload-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.policyUploadHeading {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #4a4a4a;
}

.doc-type {
  margin-bottom: 20px;
}

.doc-type label {
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  color: #4a4a4a;
}

.doc-type div {
  display: flex;
  flex-wrap: wrap;
}

.doc-type input[type="radio"] {
  margin-right: 8px;
}

.doc-type div input {
  margin-right: 10px;
}

.doc-type div label {
  margin-right: 20px;
  font-size: 1rem;
  color: #333;
}

.file-upload {
  margin-bottom: 20px;
}

.file-upload label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4a4a4a;
  display: block;
  margin-bottom: 10px;
}

.file-upload input[type="file"] {
  padding: 5px;
  font-size: 1rem;
}

.policyUploadBtn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.policyUploadBtn:hover {
  background-color: #218838;
}

.policyUploadBtn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Optional styling for file upload feedback */
.alert {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  color: white;
  text-align: center;
}

.alert.success {
  background-color: #28a745;
}

.alert.error {
  background-color: #dc3545;
}



.uploaded-docs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.uploaded-docs-table th,
.uploaded-docs-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.uploaded-docs-table th {
  background-color: #f4f4f4;
}

.policyUploadBtn {
  margin: 10px;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.policyUploadBtn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.upload-container {
  padding: 20px;
}

.policyUploadHeading {
  font-size: 24px;
  margin-bottom: 10px;
}

.file-upload {
  margin-bottom: 10px;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.uploaded-docs-table a {
  text-decoration: none;
  color: blue;
}


.upload-container {
  padding: 20px;
}

.policyUploadHeading {
  font-size: 24px;
  margin-bottom: 10px;
}

.file-upload {
  margin-bottom: 10px;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.policyUploadBtn {
  margin: 10px;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.policyUploadBtn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Card container */
.document-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

/* Individual document card */
.document-card {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.document-card h4 {
  margin-bottom: 10px;
}

.document-card p {
  margin: 5px 0;
}

.document-actions {
  margin-top: 10px;
}

.preview-btn,
.download-btn {
  text-decoration: none;
  color: white;
  background-color: #007bff;
  padding: 8px 16px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
}

.preview-btn:hover,
.download-btn:hover {
  background-color: #0056b3;
}



.upload-container {
  padding: 20px;
}

.policyUploadHeading {
  font-size: 24px;
  margin-bottom: 10px;
}

.file-upload {
  margin-bottom: 10px;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.policyUploadBtn {
  margin: 10px;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.policyUploadBtn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Card container */
.document-cards-section {
  padding: 20px;
  margin-top: 30px;
  background-color: #f9f9f9;
}

.document-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

/* Individual document card */
.document-card {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.document-card h4 {
  margin-bottom: 10px;
}

.document-card p {
  margin: 5px 0;
}

.document-actions {
  margin-top: 10px;
}

.preview-btn, .download-btn {
  text-decoration: none;
  color: white;
  background-color: #007bff;
  padding: 8px 16px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
}

.preview-btn:hover, .download-btn:hover {
  background-color: #0056b3;
}


/* Container for the document table card */
.document-table-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
  width: 80%;
  margin: 0 auto;
}

/* Title for the document table card */
.document-table-card h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #333;
}

/* Styling for the table */
.document-table {
  width: 100%;
  border-collapse: collapse;
}

.document-table th,
.document-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.document-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.document-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.document-table tr:hover {
  background-color: #f1f1f1;
}

/* Optional: Add some spacing around the table */
.document-table-container {
  overflow-x: auto;
}

.required-strick {
  color: red;
  font-size: 1rem;
}