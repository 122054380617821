h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 20px;
  /* Adjust the space between list items */
}

strong {
  font-weight: bold;
  margin-right: 5px;
}

/* Add spacing between title and description */
li strong {
  display: block;
  margin-bottom: 5px;
}