.main_blog_section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow-x: auto;
  margin: 0 -10px;
}

.card_blog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  background-color: #fff;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.blog_image {
  position: relative;
}

.blog_img {
  width: 100%;
  height: 120px;
  object-fit: fill;
  border-radius: 8px 8px 0 0;
}

.blog_category {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #3498db;
  color: #fff;
  padding: 0px 12px;
  border-radius: 50px;
  font-size: 11px;
}

.blog_content {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog_heading {
  font-size: 20px;
  margin-bottom: 12px;
}

.para_blog {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
  flex-grow: 1;
}

.blog_buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 5px;
  right: 5px;
  padding-top: 10px;
}

.blog_button {
  padding: 7px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex: 1;
  text-align: center;
  margin: 0 5px;
}

.blog_quote-btn {
  background-color: #4CAF50;
  color: #fff;
}

.blog_read-more-btn {
  background-color: #3498db;
  color: #fff;
}

.main_blog_container {
  display: flex;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
}

.main_learnMore {
  padding-left: 20px;
  color: #f6931E;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
}

.blog_container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column !important;
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
}

.blog_section {
  width: 75%;
  margin: auto;
  overflow-x: auto;
}

.learnMore {
  padding-left: 20px;
  color: #f6931E;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
}