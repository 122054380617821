/* InsuranceProcessCards.css */
.insurance-process-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .insurance-process-cards-container {
    flex-direction: column;
  }

  .process-card-container {
    width: 100% !important;
  }
}

.process-card-container {
  width: 48%;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
}

.process-heading {
  color: #333;
  font-size: 1.3rem;
}

.process-ul {
  list-style-type: none;
  padding: 0;
}

.process-li {
  color: #666;
  margin-bottom: 10px;
  font-size: .9rem;
}