.paragraph {
  overflow: hidden;
  max-height: 7.8em;
  /* Adjust this value to show the desired number of lines */
  transition: max-height 0.3s ease;
}

.paragraph.expanded {
  max-height: none;
}

.read-more,
.read-less {
  color: #40a9ff;
  cursor: pointer;
  margin-top: -.6em;
}

.colorHead {
  color: #F6931E !important;
}