.contact-container {
  margin: 0 auto;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form-group {
  margin-bottom: 8px;
  margin-top: 2px;

}

.contact-form-group input {
  border: 1px solid #c1bfbf;
  border-radius: 5px;
  padding: 5px 10px;
  width: 15rem;
}

.contact-form-group select {
  border-radius: 5px;
  padding: 5px 10px;
  width: 15rem;
}

.contact-form-group textarea {
  border-radius: 5px;
  padding: 5px 10px;
  width: 15rem;
}


.new-contact-form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}

.data-textarea {
  height: 120px;
  resize: vertical;
}

.contactus-btn,
.contact-form-button {
  padding: 10px 22px;
  background-color: #f6931e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.contactus-btn:hover {
  background-color: #f68c0b;
}

.contact-form-button:hover {
  background-color: #f68c0b;
}

.contact-form-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.contact-error {
  border: 1px solid red !important;
}

.contact-error-alert {
  color: red !important;
  margin-top: 5px;
}

.contact-success-alert {
  color: green !important;
  margin-top: 5px;
}

.required-asterisk {
  color: red;
  font-size: 16px;
}