.container{
    /* width: 100%; */
    /* height: 200vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:  3rem;
    position: relative;
    padding-top: 15rem;
/* background-color: red; */

}

.menuWrapper{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* height: 50vh; */
    /* background-color: red; */
    position: absolute;
    top: 100px;
    z-index: 99;
}

.common{
    height: 190px;
    width: 190px;
    border: 1px solid rgba(0, 0, 0, 0.315);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
}

.four , .one, .two .three{
    margin: 0 50px;
}

.comman p{
    color: rgba(0, 0, 0, 0.568);
    text-transform: capitalize;
    text-align: center;
    font-weight: 600;
}

.insuranceBody{
    /* height: 150vh; */
    /* width: 100%; */
    position: relative;
    background-color: #FFF8F1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 3rem 3rem 3rem 3rem;
    border-radius: 30px;
    /* top: -6rem; */
    z-index: 2;
}

.heading {
    padding-top: 30px;
}

.heading h1{
    font-size: 26px ;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.815);
}

.insuranceBody p{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 30px;

}
.insuranceBody h3{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.623);
}

.arrow{
    font-size: 14px;
}

.heading h2 {
    margin-bottom: 1.25rem;
}

@media screen and (max-width:1150px) {

    .container {
        padding-top: 50vh;
    }

    .menuWrapper {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        top: 30px;
        gap: 20px;
        /* flex-direction: column; */

    }
}

@media screen and (max-width:800px) {
    .container {
        padding-top: 110vh;
    }
    .menuWrapper {
        display: block;

    }
    .common {
        margin: 1rem 0;
    }
}