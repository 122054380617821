.policy-particular-details {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.policy-particular-details h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.policy-particular-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  align-items: start;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.policyParticularLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.policyParticularInput,
.policyParticularSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[disabled] {
  background-color: #f0f0f0;
}

button.add-btn,
button.calc-gross-prem-btn {
  background-color: #4b006e;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

button.add-btn:hover,
button.calc-gross-prem-btn:hover {
  background-color: #2d0043;
}

.required input,
.required select {
  border-color: red;
}

.policy-particular-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}

.policy-particular-table th,
.policy-particular-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.policy-particular-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.policy-particular-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.policy-particular-table tr:hover {
  background-color: #f1f1f1;
}

.policy-particular-table td button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.policy-particular-table td button:hover {
  background-color: #d32f2f;
}