/* hero.css */

.banner {
  position: relative;
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(74, 74, 74, 0.141);
}

.BannerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentLeft {
  flex: 1;
}

.contentRight {
  flex-shrink: 0;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentImg {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentHeading {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: -5px;
}

.contentPara {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.contentBtn {
  padding: 7px 18px;
  font-size: 1.1em;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.contentBtn:hover {
  background-color: #45a049;
}

/* Media query for tablets */
@media (max-width: 1024px) {
  .BannerContent {
    width: 90%;
  }

  .contentRight {
    margin-left: 15px;
    /* Reduce margin on tablets */
  }

  .contentImg {
    width: 80px;
    height: 80px;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .BannerContent {
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contentRight {
    margin-left: 0;
    margin-top: 10px;
    /* Add margin to separate from contentLeft on mobile */
  }

  .contentImg {
    width: 60px;
    height: 60px;
  }

  .contentHeading {
    font-size: 1.5rem;
    margin-top: 4rem;
    /* Adjusted margin for mobile */
  }

  .contentPara {
    font-size: 0.9rem;
    margin-bottom: 15px;
    /* Adjusted margin for mobile */
  }

  .contentBtn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}