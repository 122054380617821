.policy-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.policy-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-width: 600px;
  position: relative;
}

.policy-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}