/* BenefitsCard.css */

.benefits-card {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 25px;
}

.policy_benefits_heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 20px 0 30px;
  color: #333;

}

.icon-container {
  padding-left: 2rem;
}

.content-container {
  display: flex;
  align-items: center;
  align-content: center;
}

.text-container {
  flex: 1;
  padding-left: 20px;
}

.benefit-icon {
  width: 70px;
  height: 70px;
  fill: #fff;
}

.heading {
  font-size: 1.1rem;
  margin-bottom: 5px;
  font-weight: bold;
}

.brand-paragraph {
  font-size: .9rem !important;
  color: #333 !important;
}