.main6 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background-color: rgba(38, 37, 37, 0.849);

}

.review {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: red; */
    /* height: 100vh; */
    width: 45%;
    border: 1px solid rgba(0, 0, 0, 0.142);
    /* border-radius: 7px; */
    background-color: white;
    text-align: center;

}

.review input {
    display: none;

}

.review1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.review1 h1 {
    padding-bottom: 1rem;
    font-size: 25px;
    color: rgba(26, 26, 85, 0.63);
}

.review1 p {

    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.591);

}

.review2 {
    /* background-color: rgb(53, 101, 142); */
    width: 70%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border: 1px solid rgba(0, 0, 0, 0.183);
    border-radius: 10px;

}

.review2 h5 {
    color: rgba(0, 0, 0, 0.498);
    font-size: 12px;
}

.review2 h3 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.648);
    margin-top: 5px;
}

.review3 {
    margin-top: 1rem;
    width: 70%;
}

.review3 span {
    font-size: 15px;
    /* font-weight: bold; */
    color: rgba(0, 0, 0, 0.648);

}

.card7 h3 {
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.481);
    position: absolute;
    top: 50%;
    left: 48%;
}


.card7 img {
    height: 27vh;
    width: 100%;
    color: orange;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.review3 h4 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.548);

}

.review3 h4 span {
    color: orange;
}

.review .card7 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 30vh;
    border: 3px dashed rgba(0, 0, 0, 0.231);
    border-radius: 8px;
    width: 100%;
    border-radius: 10px;
}

.btn7 button {
    padding: 10px 45px;
    margin-top: 20px;
    background-color: rgba(157, 152, 139, 0.475);
    border: none;
    color: rgba(44, 35, 35, 0.716);
    font-size: 15px;
    font-weight: 700;
    /* margin-left:40% ; */
    border-radius: 8px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}