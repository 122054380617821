.policy-form {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
} */


.submit-btn-policy-broker {
  width: 100px;
  padding: 7px 10px;
  font-size: 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.exit-btn-policy-broker {
  width: 100px;
  padding: 6px 10px;
  font-size: 15px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.saveExitBtnGrp {
  display: flex;
  margin-top: 20px;
}

.brokerPolicyHeading {
  font-size: 1.5rem;
  margin-bottom: 20px;
}