.homePageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* min-height: 120vh; */
    width: 100%;
    background: linear-gradient(to top right, rgb(255, 231, 187), rgb(65, 2, 95));
    padding-top: 2.5rem;
    /* padding-bottom: 5rem */
}

.imgCard {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 1rem;
    padding: 1rem 0 2.5rem 0;


}

button {
    color: #000;
}

.imgCard p {
    color: white;
    /* margin-left: -1.6rem; */
}

.imgCard img {
    margin: 0 3rem;
}

.insWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.cardWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2.5rem 0 1rem 0;

}

.card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 220px;
    width: 220px;
    background-color: #f2f2f2;
    border-radius: 10px;
    border: none !important;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.card img {
    max-width: 100%;
    max-height: 70%;
}

.card button {
    width: 160px;
    height: 34px;
    padding: 0 15px;
    cursor: pointer;
    color: #ffffff;
    background-color: #420260;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.card button:hover {
    background-color: #4f0670;
    /* Hover color */
}

.img-p-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ant-modal-footer {
    display: none;
}

.ant-modal-footer {
    color: rgba(0, 0, 0, 0.45) !important;
}

.ant-modal-body {
    padding: 0;
}

.modal .egnAnO {
    margin-left: .3rem;
    padding: 0 !important;
    width: 100% !important;
}

.ant-modal-close {
    color: rgba(0, 0, 0, 0.45) !important;
}

@media screen and (max-width:530px) {
    .imgCard {
        display: block;
    }

    .img-p-container {
        margin: 1rem auto;
    }

    .insWrapper h1 {
        text-align: center;
    }
}

@media screen and (max-width:1060px) {
    .cardWrapper {
        display: grid;
        /* overflow-y: auto; */
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding-bottom: 2rem;
    }

    .cardWrapper .card {
        margin: 1rem auto;
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .cardWrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;

    }

    .insWrapper h1 {
        margin-top: 80px;
    }
}

.home_heading {
    color: white;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 0px !important;
}

.home_para {
    color: white;
    font-size: 1rem;
    text-align: center;
}