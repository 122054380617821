.pos_input_container .camera {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: -1rem;
}

.camera h6 {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.468);

}

.cameras {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #F6931E;
    border-radius: 50%;
}

.cameras .icon {
    background-color: #ffd4a4;
    width: 100px;
    border-radius: 50%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.camera .icon span {
    color: #fca448;
    font-size: 3.3rem;
    display: flex;
}

.camera h5 {
    font-weight: bolder;
    color: #605F5E;
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
}

.document input {
    display: none;
}

.logo p {
    color: orange;
    text-decoration: underline;
}


.cardChange {
    display: none;
}

.upload_documents {
    display: flex;
    justify-content: space-between;
    margin: 10px 50px 0px 25px;
}

.imageViewer {
    border: 2px dashed rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 226px;
    height: 146px;
}

.image_upload {
    width: 226px;
    height: 146px;
    border-radius: 10px;
}


.documents h5 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.482);
}

.documents h6 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    opacity: 0;
}


.upload_documents div h4 {
    color: #605F5E;
    font-size: 18px;
    font-weight: 700;
    font-family: sans-serif;
}

/* .upload_documents2 */
div.main_container h4 {
    color: #61605f;
    margin-top: 4.6%;
    margin-bottom: 2.6%;
    margin-left: 4.6%;
    font-size: 1.2rem;
    font-weight: 700;
}

.upload_documents3 h4 {
    color: #61605f;
    font-size: 1.2rem;
    font-weight: 700;
}

.upload_image {
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #605F5E;
    position: relative;
    margin-top: 16px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageViewer p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -2px;
    font-weight: bold;
    padding:0 5px;
}

.document_image {
    width: 13.7rem;
    height: 8.2rem;
    border-radius: 10px;
    color: #F6931E;
    text-align: center;
    position: absolute;
    display: none;
    margin-bottom: 5px;
}
.review_document_image {
    width: 29rem;
    height: 22rem;
    border-radius: 10px;
    color: #F6931E;
    text-align: center;
    /* position: absolute; */
    display: none;
    margin-bottom: 5px;
}

.sideContainer {
    margin-top: -14px;
}

.profile_image {
    position: relative;
    width: 100px;
    border-radius: 50%;
    height: 100px;
    display: none;
}