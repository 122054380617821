/* Form.css */

.form-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #faf9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-label {
  display: block;
  margin-bottom: 10px;
}

.form-data {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.health-form-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.health-form-btn:hover {
  background-color: #0056b3;
}

.error {
  border-color: red;
}

.health-form-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.success-message {
  color: rgb(33, 194, 33);
}

.error-message {
  color: red;
}