/* CSS for the table-like structure */
.table-container {
  display: table;
  width: 100%;
  margin-top: 2rem;
}

.health-container-heading {
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin: 2rem 0;
  color: #f6931e;
  font-weight: bold;
  text-align: center;
}

/* Table Structure */
.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
}

.table-cell p {
  margin: 0;
}

.table-row:nth-child(even) {
  background-color: #f9f9f9;
}

/* General Health Section Styles */
.health-container {
  width: 75%;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
}

.health-part-one {
  display: flex;
  flex-wrap: wrap;
}

.health-column {
  flex: 1;
  padding: 0 10px;
  min-width: 300px;
  /* Ensure columns don't get too narrow */
}

.health-btn-container {
  margin-top: 2rem;
}

.health-button {
  margin: 8px 8px 0 0;
  padding: 10px 20px;
  background-color: rgb(28, 132, 28);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8rem;
}

.health-button:hover {
  background-color: rgb(0, 102, 0);
}

/* Section Titles */
.health-part-two,
.health-part-three,
.health-part-four,
.health-part-five,
.health-part-six,
.health-part-seven {
  margin-top: 2rem;
}

.health-part-two p,
.health-part-three p,
.health-part-four p,
.health-part-five p,
.health-part-six p,
.health-part-seven p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #f6931e;
  font-weight: bold;
}

/* Banner Image */
.health-banner-image {
  width: 100%;
  height: 100%;
}

.health-insurance-banner {
  width: 100%;
  height: 500px;
  margin: 3rem 0;
}

/* For Tablets and Smaller Screens */
@media screen and (max-width: 1024px) {
  .health-container {
    width: 90%;
  }

  .health-container-heading {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .table-container,
  .table-row,
  .table-cell {
    display: block;
    width: 100%;
  }

  .table-cell {
    padding: 10px 0;
    border-bottom: none;
  }

  .table-cell p {
    margin: 0 0 10px 0;
  }

  .health-part-one {
    display: block;
  }
}

/* For Mobile Screens */
@media screen and (max-width: 768px) {

  .health-container {
    width: 100% !important;
    padding: 0 15px;
  }

  .health-insurance-banner {
    height: 300px;
    margin: 2rem 0;
  }

  .health-column {
    padding: 0 5px;
    margin-bottom: 20px;
  }

  .health-button {
    font-size: 0.75rem;
    padding: 8px 16px;
  }

  .health-container-heading {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

/* For Small Mobile Screens */
@media screen and (max-width: 480px) {
  .health-container {
    width: 100% !important;
    padding: 0 10px;
  }

  .health-insurance-banner {
    height: 200px;
    margin: 1rem 0;
  }

  .health-container-heading {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .table-container,
  .table-row,
  .table-cell {
    display: block;
    width: 100%;
  }

  .health-button {
    font-size: 0.7rem;
    padding: 7px 14px;
  }
}