.servicing-details-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.servicing-details-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.servicing-details-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  align-items: start;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.servicingDetailsLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.servicingDetailsInput,
.servicingDetailsSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[disabled] {
  background-color: #f0f0f0;
}

button.info-btn {
  background-color: #4b006e;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 3px;
}

button.info-btn:hover {
  background-color: #2d0043;
}

.required input,
.required select {
  border-color: red;
}