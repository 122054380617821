.container2 {
  /* display: grid; */
  display: grid;
  grid-template-columns: 29rem 27rem;
  grid-gap: 1rem;
}
.data{
  color: #605F5E;
  font-weight: 700;
  font-size: 20px;
}