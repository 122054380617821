.quick-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4b006e;
  height: 110px;
}

.title_body {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleNavigation {
  font-size: 13px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title_heading {
  font-size: 30px;
  color: white;
  margin-bottom: 0 !important;
}

.title_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 12px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .title_body {
    width: 95%;
    /* Adjusted width for smaller screens */
  }

  .titleNavigation {
    font-size: 12px;
    /* Adjusted font size for smaller screens */
  }

  .title_heading {
    font-size: 20px;
    /* Adjusted font size for smaller screens */
  }
}