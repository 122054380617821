/* App.css */
.page-container {
  display: flex;
  height: auto;
}

.left-section {
  width: 16%;
  /* background-color: #f2f2f2; */
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-top: 20px;
}

.center-section {
  width: 65%;
  background-color: #ffffff;
}

.right-section {
  width: 19%;
  padding-top: 20px;
  /* background-color: #f2f2f2; */
}

.company-item {
  margin-bottom: 10px;
  width: 100%;
}

.company-item ul {
  list-style: none;
  padding: 0;
}

.company-item li:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Adjustments for small screens */
@media (max-width: 768px) {
  .page-container {
    flex-direction: column;
    /* Stack sections vertically */
    align-items: center;
    /* Center align sections */
  }

  .left-section {
    display: none !important;
  }

  .center-section {
    width: 100%;
    /* Take up full width on small screens */
  }
}

/* Styles for larger screens */
.page-container {
  display: flex;
  height: auto;
}

.center-section {
  width: 65%;
  background-color: #ffffff;
}

.right-section {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
}