.customer-detail-page {
  width: 100%;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
}

.customer-card {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 1px;
  right: 10px;
  color: #ff6b6b !important;
  border: none;
  cursor: pointer;
  font-size: 30px !important;
}

.close-button:hover {
  color: #d55d5d;
}

.menu-tabs {
  display: flex;
  background-color: #e7e1e1;
  padding: 10px;
  border-radius: 8px 8px 0 0;
}

.menu-tabs button {
  color: rgb(34, 2, 34) !important;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
}

.menu-tabs button.active {
  border-bottom: 2px solid #F6931E;
  color: #ff6b6b;
}

.menu-tabs button:hover {
  color: #ff6b6b;
}


.customer-card,
.details-section,
.kyc-status {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-section {
  display: flex;
  align-items: center;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #F6931E;
}

.profile-info h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.profile-info p {
  margin: 5px 0;
  color: #555;
}

.details-section h3,
.kyc-status h3 {
  font-size: 18px;
  color: #F6931E;
  margin-bottom: 10px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.detail-row:last-child {
  border-bottom: none;
}

.detail-row p {
  margin: 0;
  color: #333;
}

.policy-table {
  width: 100%;
  border-collapse: collapse;
}

.policy-table th,
.policy-table td {
  border: 1px solid #f1f1f1;
  padding: 10px;
  text-align: left;
}

.policy-table th {
  background-color: #F6931E29;
  color: #000;
  font-weight: normal;
}

.policy-table-section h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #000;
}