.selected {
    background-color:rgb(246, 147, 30,.16);
}
.menu-item {
    border-radius: 6px;
    padding: 0.5rem;
    margin-bottom: .6rem;
    cursor: pointer;
}
.menu-title {
    /* z-index: 9;  changed */
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1rem !important;
    color: #605F5E;
    font-weight: normal;
}
.arrow-right {
    
}
.selected-text {
    color: #F6931E;
    font-weight: 600;
}

.indicator {
    background-color: #F6931E;
    height: 50px;
    width: 6px;
    border-radius: 6px 0 0 6px;
    position: absolute;
    right: -15px;
    bottom: -6px;
}

.list_none{
    list-style: none;
    display: none;
}

.indicatorSub {
    height: 50px;
    width: 6px;
    border-radius: 6px 0 0 6px;
    position: absolute;
    right: -15px;
    bottom: -6px;
}