.main8{

    width: 100%;
    
    height: 160vh;
    display: flex;
    justify-content: center;
    
    background-color: rgba(38, 37, 37, 0.849);
    
}
.Done{
    margin-top: 3rem;
    display: flex;
    align-items: center;
   
    flex-direction: column;

 height: 85vh;
 width: 45%;
 border: 1px solid rgba(0, 0, 0, 0.142);

 background-color: white;
 text-align: center;
 
 }
 .Done1{

height: 50%;
width: 100%;
 }

 .Done1 img{


width: 45%;
height: 100%;
 }


.Done2 {
    
margin-top: 1rem;
color: rgb(26, 216, 26);
font-weight: 600;
font-size: 20px;
padding-bottom: 2rem;
width: 80%;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}

.Done2 h6{
 
  
line-height: 2rem;
color: rgba(0, 0, 0, 0.563);

font-weight: bold;
font-size: 13px ;
text-align: center;
}
 
.btn9 button{

    padding: 10px 45px;
    margin-top: 2rem;
    background-color: rgb(255, 157, 0);
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 600;
    
    border-radius: 8px;
}    