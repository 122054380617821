.co-broker-section {
  margin-bottom: 20px;
}

.checkbox-container {
  margin-bottom: 10px;
}

.co-broker-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

/* Label Styling */
.coBrokerLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input and Select Styling */
.coBrokerInput,
.coBrokerSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button Styling */
button.coBrokerButton {
  background-color: #20c997;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

button.coBrokerButton:hover {
  background-color: #17a589;
}

/* Table Styling */
.coBrokerTableContainer {
  margin-top: 20px;
}

.coBrokerTable {
  width: 100%;
  border-collapse: collapse;
}

.coBrokerTableHeader,
.coBrokerTableData {
  border: 1px solid #ddd;
  padding: 8px;
}

.coBrokerTableHeader {
  background-color: #f4f4f4;
  text-align: left;
}

/* Remove Button Styling */
button.remove-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

button.remove-btn:hover {
  background-color: #ff1a1a;
}