.backAndCopyPaymentLink {
  display: flex !important;
  justify-content: end;
  align-items: center;
  margin-bottom: 1rem;
}

.back_btn_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 550;
  cursor: pointer;
}

.copy-link-btn-para {
  font-weight: 550 !important;
  cursor: pointer;
}

.preview_plan_data {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  column-gap: 1rem;
}

.preview_plan_data_para p {
  font-size: 1.2rem;
  font-weight: 550;
  margin-left: 1rem;
}

.brand-img{
  object-fit: contain;
}