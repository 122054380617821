/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* Define CSS animation for the reload icon */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.reload-animation {
  animation: rotate 1s linear infinite;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal h2 {
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.modal-buttons button:first-child {
  background: #db1717;
}

.modal-buttons button:last-child {
  background: #1890ff;
  color: white;
}

#rejectReason {
  width: 100%;
  height: 100px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.m-0 {
  margin-right: 0;
}

.lifeData {
  column-gap: 44px;
}

.pos_name {
  width: 170px;
  text-align: center;
}

.close-pos-model {
  color: red;
  height: 20px;
  font-size: 40px;
  position: absolute;
  top: 2px;
  right: 20px;
  cursor: pointer;
}

.main-model-data {
  position: relative !important;
}