.customer-info-section {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.customer-info-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.customer-info-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  align-items: start;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.customerInfoSectionLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.customerInfoSectionInput,
.customerInfoSectionSelect {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[disabled] {
  background-color: #f0f0f0;
}

button.info-btn,
button.add-btn {
  background-color: #4b006e;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 3px;
}

button.info-btn:hover,
button.add-btn:hover {
  background-color: #2d0043;
}

.required input {
  border: 1px solid red;
}
/* Popup container */
.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 80%;
  max-width: 800px;
  height: 80%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: hidden;
}

/* Popup header */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2d0043;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
}

.popup-header h3 {
  margin: 0;
  color: white;
  font-size: 18px;
  text-decoration: none !important;
}
.search-input-cust-info{
  width: 300px !important;
  padding: 8px;
  margin-right: 60px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color:  #2d0043;
  box-sizing: border-box;
}

/* Popup body */
.popup-body {
  padding: 15px;
  max-height: 800px;
  overflow-y: auto;
}

/* Customer list */
.customer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.customer-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-item div {
  font-size: 14px;
}

.select-btn {
  background: #2d0043;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.select-btn:hover {
  background: #4b006e;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;
}

.close-btn:hover {
  color: #ddd;
}

.add-customer-btn {
  margin-top: 20px;
  background-color: #2d0043;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  /* Full-width button */
}
.customer-form {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.customer-form h3 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
}

.customer-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.customer-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
}

.customer-form button {
  background-color: #2d0043;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  /* Full-width button */
}

.customer-form button:hover {
  background-color: #4b006e;
}
