.labelUpper {
  position: absolute;
  margin: -12px 10px 10px 8px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #73787d;
}

.labelUpperMobile {
  position: absolute;
  margin: -12px 10px 10px 8px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #73787d;
}

.custom-input {
  /* Your existing styles */
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 60px;
  margin-top: 5px;
  margin-right: 22px;
  text-indent: 2px;
  box-sizing: border-box;
  text-align: left;
}

.custom-input {
  /* Your existing styles */
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 50px;
  margin-top: 5px;
  margin-right: 22px;
  text-indent: 2px;
  box-sizing: border-box;
  text-align: left;
}

.custom-input-mobile {
  /* Your existing styles */
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 45px;
  margin-top: 5px;
  margin-right: 10px;
  text-indent: 2px;
  box-sizing: border-box;
  text-align: left;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #f6931e;
}

.custom-input::placeholder {
  color: #73787d;
  font-size: 0.7rem;
  padding-left: 5px;
}

.inputBoxSum {
  width: 102px !important;
}

.inputBoxSum-mobile {
  width: 90px !important;
}

.button-option {
  background-color: #41025F !important;
  color: #fff;
  font-weight: bold;
  border: 1px solid #f6931e !important;
  padding: 8px 12px !important;
  cursor: pointer !important;
  font-size: 17px !important;
}

.button-option:hover {
  background-color: #97590d;
  border: 1px solid#41025F
}

/* custom-modal.css */
.ant-modal-confirm-btns {
  display: none;
}

.lumpsum-income {
  width: 200px !important;
  display: flex;
  margin-bottom: 5px;
  border-radius: 6px;
  border: 1px solid #41025F;
  padding: 4px 3px;
}

.lumpsum-income::placeholder {
  font-size: 12px;
  padding-left: 5px;
}

.lumpsum-income:focus {
  outline: none;
  border: 1px solid #f6931e;
}

select:disabled {
  opacity: 0.6;
  /* Adjust the opacity value as needed */
  color: #999;
  /* Change the text color to a lighter shade if desired */
}