/* loading.css */
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}