/* InsuranceSteps.css */

.insurance-steps-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.icon-step-container {
  flex: 0 0 calc(20% - 10px);
  /* Five cards in a row with a little space between */
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.step .icon {
  /* Styles for the icon */
  /* You can customize this for your icon */
  font-size: 24px;
  /* Adjust the icon size as needed */
}

.step-description {
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
}

.step-title {
  margin-top: 3px;
  text-align: center;
  font-size: 12px;
  /* Adjust the title font size */
}