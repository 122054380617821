/* ContactUsPage.css */

/* Base Styles */
.contact-us-page {
  width: 65%;
  margin: 0 auto;
}

.contact-info-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.left-part {
  width: 49%;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.right-part {
  width: 49%;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px 25px;
}

.contact-form-detail {
  width: 100%;
}

.right-contact-detail {
  padding: 0 20px;
}

.contact-detail svg {
  margin-right: 8px;
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group select,
.form-group textarea {
  border: 1px solid #c1bfbf;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  max-width: 400px;
  /* Ensure elements don’t grow too large */
}

form label {
  display: flex;
  align-items: center;
}

.contact-form-button {
  background-color: #3498db;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Map Section */
.map-section {
  margin-bottom: 20px;
}

.location {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.location svg {
  margin-right: 8px;
}

.general-enquiry,
.customer-support {
  margin-bottom: 20px;
}

.beng_brach_data,
.nagpur_brach_data {
  margin-top: 20px;
}

.cs_gi_sm {
  margin: 15px 0px;
  font-weight: bold;
  font-size: 20px;
}

.social-media-icons {
  display: flex;
  margin-top: 20px;
  padding: 10px;
}

/* Responsive Styles for Tablets and Mobile Devices */

/* For tablets and small screens */
@media (max-width: 768px) {
  .contact-us-page {
    width: 90%;
  }

  .contact-info-section {
    flex-direction: column;
  }

  .left-part,
  .right-part {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-form-detail {
    padding: 0 10px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    max-width: none;
    /* Remove max-width constraint */
  }

  .contact-form-button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
  }

  .address-details {
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 8px;
  }

  .social-media-icons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-media-icons a {
    margin: 5px;
  }

  .blog_section {
    padding: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .cs_gi_sm {
    font-size: 18px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 14px;
  }

  .contact-form-button {
    font-size: 0.9rem;
  }
}