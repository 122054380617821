/* Styling for the card */
.card {
  border: 1px solid #f6931e;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.card p {
  font-size: 1rem;
  color: #555;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.career_banner {
  width: 100%;
  height: 500px;
  margin: 3rem 0;
}

.career_banner_img {
  width: 100%;
  height: 100%;
}

.careerContainer {
  margin: 0 auto;
  padding: 0 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 4rem;
}

.career_heading {
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  font-size: 2rem;
  color: #f6931e
}

.career_heading_small {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #f6931e;
  font-weight: bold;
}

.joinTeam h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #f6931e;
  font-weight: bold;
}

.jobOpenings {
  margin: 20px 0;
}

.whyJoin {
  margin: 20px 0;
}

.submitResumeForm {
  margin: 20px 0;
}

.submitResumeForm form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.submitResumeForm input,
.submitResumeForm button,
.submitResumeForm select
 {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #f6931e;
  border-radius: 5px;
}

.submitResumeForm input[type='text'],
.submitResumeForm input[type='email'],
.submitResumeForm input[type='tel'],
.submitResumeForm input[type='file'],
.submitResumeForm select {
  flex: 1;
  margin-right: 10px;
}

.submitResumeForm input[type='file'] {
  flex: 2;
}

.submitResumeForm button {
  flex: 1;
  background-color: #f6931e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitResumeForm button:hover {
  background-color: #d07f1b;
}

.diversity {
  margin: 30px 0 0 0;
}

.joinUsLast {
  margin: 20px 0 10px 0;
  font-weight: bold;
}
.position{
  background-color: white;
}

@media screen and (max-width: 768px) {
  .submitResumeForm input[type='file'] {
      flex: 2;
      width: 280px;
    }
}

@media screen and (max-width: 480px) {
  .submitResumeForm input[type='file'] {
      flex: 2;
      width: 280px
    }
}