@font-face {
    font-family: 'Font';
    src: url(./assets/fonts/Proxima\ Nova\ Font.otf);
}

@font-face {
    font-family: 'calist';
    src: url(./assets/fonts/calist.ttf);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Font';
}

.bannerForWhenUserComesFirst {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80vh;
    background-color: #fff;
    padding: 3rem 2rem;
    z-index: 9999;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-in-out;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 20px;
    outline: none;
    background: none;
    display: flex;
    align-items: center;
}

.bannerForWhenUserComesFirst img {
    width: 100%;
    height: 100%;
}


.whatsapp-icon {
    position: fixed;
    bottom: 30px;
    right: 40px;
    z-index: 1000;
}

.whatsapp-icon a {
    background-color: #25d366;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-size: 24px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.whatsapp-icon a:hover {
    background-color: #f2f2f2;
    color: #25d366;
}

@media screen and (max-width: 768px) {
    .whatsapp-icon {
        bottom: 20px;
        right: 15px;
    }
}