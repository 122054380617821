.nav-wrapper {
    /* height: 15vh; */
    padding: 1rem .5rem;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100;
    /* max-width: 100vw;; */
}


.header-menu {
    text-decoration: none;
    color: rgb(3, 3, 3);
    padding: 0 1rem;
    text-align: center;
}

.prod {
    /* display: flex;
    align-items: center; */
    text-align: center;
    margin-left: 0.5rem;
    position: relative;
    top: 0.2rem;
}


.links button {
    /* background-color: #41015F; */
    background-color: #F6931E;
    border: none;
    color: rgb(3, 3, 3);
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
    color: white;
    margin-left: rem;
}

.links .pos {
    /* Default button styles */
    border: 2px solid #f6931E;
    color: #f6931E;
    padding: 8px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 10px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;

    /* Styles for mobile screens */
    @media (max-width: 600px) {
        width: 150px;
        margin: 0 auto;
        padding: 10px 12px;
        font-size: 14px;
    }
}

@media screen and (max-width:980px) {
    .nav-wrapper {
        justify-content: space-between;
        padding: 1rem 1.5rem;
        align-items: baseline;
        top: .2rem;
    }

    .links {
        padding-top: 100px;
        display: flex;
        flex-direction: column;
    }

    .menus {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .links button {
        margin: auto;
        margin-bottom: 2rem;
    }

    .links a {
        margin: 1.25rem auto;
    }

    .links a:first-child {
        margin-top: 0;
    }

    .links .dash {
        display: flex;
        justify-content: center;
        margin: 1rem 0;
    }

    .logo img {
        width: 70%;
    }
}

button {
    color: #fff !important;
}

.nav-wrapper .links .active {
    color: #f6931E;
}

.links a:hover {
    color: #F6931E !important;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

header {
    height: 58px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
}

.nav-area {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    height: 58px;
}

.links {
    display: flex;
    align-items: center;
}

.menus {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-items {
    position: relative;
    font-size: 14px;
    color: #212529;
}

.menu-items a {
    display: block;
    font-size: inherit;
    text-decoration: none;
    color: #212529;
}

.menu-items a {
    color: #212529 !important;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 0.5rem .8rem;
    display: block;
    text-decoration: none;
}

.menu-items a:hover {
    color: #f6931E !important;
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute;
    right: 0;
    left: auto;
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 11rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #f2f2f2;
    border-radius: 5px;
    display: none;
}

.dropdown.show {
    display: block;
}

.dropdown.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

.headerTitle {
    font-weight: 600;
    margin-left: .5rem;
    margin-right: .7rem;
}

.profile_header {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-left: 1rem;
    border: 2px solid #F6931E;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}

.rolesAndName {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rolesPara {
    font-size: .8rem;
    color: #F6931E;
    font-weight: 600;
    padding-left: 1rem;
}