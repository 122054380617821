.home {
    height: 400vh;
    width: 100%;
}
/* Add styles for the collapsed icon */
.collapsed-icon {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 50px;
    background-color: #333;
    cursor: pointer;
    border-radius: 0 50% 50% 0;
    z-index: 1000;
    transition: all 0.3s ease;
}

.collapsed-icon:hover {
    background-color: #ffcc00;
    transform: scale(1.2);
}

.sticky-social-bar {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background-color: #333;
    transition: all 0.3s ease;
    overflow: hidden;
    padding-right: 10px;
    /* Space for the toggle icon */
}

.sticky-social-bar.collapsed {
    transform: translate(-100%, -50%);
}

/* Arrow toggle icon */
.toggle-icon {
    background-color: #000;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Style for individual social icons */
.sticky-social-bar a {
    display: block;
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease, transform 0.3s ease;
}

.sticky-social-bar a:hover {
    color: #ffcc00;
    transform: scale(1.2);
}

.logoPadding {
    padding: 10px 10px 5px 8px;
}

.facebook {
    background-color: #3b5998;
}

.twitter {
    background-color: #333;
}

.instagram {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80);
}

.youtube {
    background-color: #cd201f;
}

.linkedin {
    background-color: #0077b5;
}

.info {
    background-color: #17a2b8;
}

@media screen and (max-width: 768px) {
    .sticky-social-bar {
        left: -50px;
    }

    .sticky-social-bar:not(.collapsed) {
        left: 0;
    }
}