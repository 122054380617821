.mainWrapper {
    width: 100%;
    height: 160vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}


.cancelBtn {
    position: absolute;
    top: -10px;
    right: 5px;
    margin: 1rem;
    font-size: 2rem;
    color: rgb(166, 29, 29);
    cursor: pointer;
}

.containerContent {
    display: flex;
    justify-content: space-around;
    height: 913px;
    z-index: 10;
    width: 65%;
    border-radius: 7px;
    background-color: white;
    position: relative;
}

.side-div {
    width: 286px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    position: relative;
    background-color: white;
    border-radius: 10px 0 0 10px;

}

.side-div p {
    position: absolute;
    top: 30px;
    left: 60px;
    font-weight: 700;
    font-size: 24px;
    color: #41015F;
    font-family: system-ui;
}

.form {
    margin: -0.5rem;

}

.side-div a {
    display: flex;
    text-decoration: none;
    padding-top: 7rem;
    color: rgba(31, 30, 30, 0.364);
}


.side-div a .icons1 {
    position: absolute;
    left: 30px;
    font-size: 23px;
}

.form h3 {
    position: absolute;
    left: 70px;
    font-size: 18px;
    color: #605F5E;
}

.side-div span::after {
    position: absolute;
    content: '';
    width: 5px;
    height: 85px;
    border-left: 2px dashed rgba(146, 137, 137, 0.46);
    left: 2.5rem;
    margin-top: 25px;
}







@media only screen and (min-width: 1900px) {
    .side-div span::after {
        position: absolute;
        content: '';
        width: 5px;
        height: 80px;
        border-left: 2px dashed rgba(146, 137, 137, 0.46);
        left: 2.5rem;
    }
}