/* CSS for the table-like structure */
.table-container {
  display: table;
  width: 100%;
  margin-top: 2rem;
}

.health-container-heading {
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  color: #f6931e;
  font-style: bold;
  text-align: center;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
}

/* Additional styling if needed */
.table-cell p {
  margin: 0;
  /* Additional styles for the text */
}

/* Alternating row colors (optional) */
.table-row:nth-child(even) {
  background-color: #f9f9f9;
}



/* health */
.brand-container {
  /* width: 60%; */
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 10px;
}

.health-part-one {
  display: flex;
}

.health-column {
  flex: 1;
  padding: 0 10px 0 0;
}

.health-btn-container {
  display: flex;
  justify-content: space-around;
}

.health-button {
  margin: 8px 8px 0 0;
  padding: 10px 20px;
  background-color: rgb(28, 132, 28);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8rem;
}

.health-button:hover {
  background-color: rgb(0, 102, 0);
}

/* health part two */
.health-part-two {
  margin-top: 2rem;
}

.brand-part-four p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 2rem 0;
  color: #f6931e;
  font-style: bold;
}

.health-part-five p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #f6931e;
  font-style: bold;
}

.health-part-six p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 2.5rem 0 1.5rem 0;
  color: #f6931e;
  font-style: bold;
}

.health-part-seven p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 2.5rem 0 1.5rem 0;
  color: #f6931e;
  font-style: bold;
}

.brand-header-heading {
  margin-top: 2rem;
  font-size: 25px;
  line-height: 20px;
  color: #f6931e;
  font-style: bold;
}

.brand-header-para {
  font-size: 20px;
  line-height: 14px;
  margin-bottom: 2rem;
  color: #000;
  font-style: italic;
}

.brand-sec-two {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.brand_sec_two_card {
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  margin-top: 50px;
}

.sec_two_para_1 {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .brand-sec-two {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .health-column p {
    font-size: 12px;
    /* Adjust the font size as needed */
  }
}

.health-column p h2 {
  line-height: 1.5rem;
  letter-spacing: 0.5px;
}